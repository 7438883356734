<template>
  <v-container :class="isMobile ? 'mx-0 px-0 my-1 py-1' : ''">
    <v-form
      ref="form_ingreso"
      @submit.prevent="GuardarServicio"
      v-model="validForm"
    >
      <v-card
        class="mx-auto"
        elevation-6
        :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
        max-width="90%"
      >
        <v-card-title
          style="height: 50px"
          :style="'color: ' + paletColor.buttons.cancel.text + ';'"
        >
          <v-row>
            <v-col>Servicio</v-col>
            <v-col align="right"
              ><v-btn
                :style="
                  'background-color: ' + paletColor.card.backgroundgral + ';'
                "
                density="compact"
                icon="mdi-close-circle-outline"
                @click="volverMenu"
              ></v-btn
            ></v-col>
          </v-row>
        </v-card-title>
        <v-card-text
          :style="'background-color: ' + paletColor.colorNeutro + ';'"
        >
          <v-row dense v-if="servicio" class="pt-4">
            <v-col cols="12" md="6">
              <frm_text
              ref="nombre"
                labelSet="Nombre" 
                :valorSet="servicio.nombre"
                :readonlySet="
                  this.servicio.usuario_id == this.servicio.usuariocrea_id
                "
                :requeridoSet="true"
                minLengthSet="10"
                maxLengthSet="50"
                MensajeAyudaSet="Nombre del servicio"
                textoAlternativoSet="Nombre del Servicio"
                :validatingSet="validando"
              />
            </v-col>
            <v-col cols="6" md="3">
              <frm_num
              ref="porcentaje_comision"
                labelSet="Comisión"
                posIconSet="mdi-percent-outline"
                :valorSet="servicio.porcentaje_comision"
                :requeridoSet="true"
                :valorMinimoSet="0"
                :valorMaximoSet="100"
                MensajeAyudaSet="Porcentaje de comisión que cobra el servicio"
                textoAlternativoSet="Comisión del Servicio"
                :validatingSet="validando"
              />
            </v-col>
            <v-col cols="6" md="3">
              <frm_num
              ref="porcentaje_descuento"
                labelSet="Descuento"
                posIconSet="mdi-percent-outline"
                :valorSet="servicio.porcentaje_descuento"
                :requeridoSet="true"
                :valorMinimoSet="0"
                :valorMaximoSet="100"
                MensajeAyudaSet="Porcentaje de descuento que otorga el servicio al pasajero"
                textoAlternativoSet="Descuento del Servicio"
                :validatingSet="validando"
              />
            </v-col>
            <v-col cols="6" md="3">
              <frm_num
              ref="bajada_bandera"
                labelSet="Bajada de Bandera"
                preIconoSet="mdi-currency-usd"
                :valorSet="servicio.bajada_bandera"
                :requeridoSet="true"
                :valorMinimoSet="0"
                :valorMaximoSet="100000"
                MensajeAyudaSet="Valor de bajada de bandera"
                textoAlternativoSet="Bajada de Bandera"
                :validatingSet="validando"
              />
            </v-col>
            <v-col cols="6" md="3">
              <frm_num
              ref="valor_kilometro"
                labelSet="Valor kilometro"
                preIconoSet="mdi-currency-usd"
                :valorSet="servicio.valor_kilometro"
                :requeridoSet="true"
                :valorMinimoSet="0"
                :valorMaximoSet="100000"
                MensajeAyudaSet="Valor del kilometro (se calcula proporcionalmente cada 200 mts)"
                textoAlternativoSet="Valor kilometro"
                :validatingSet="validando"
              />
            </v-col>
            <v-col cols="6" md="3">
              <frm_num
              ref="valor_minuto_espera"
                labelSet="Valor minuto espera"
                preIconoSet="mdi-currency-usd"
                :valorSet="servicio.valor_minuto_espera"
                :requeridoSet="true"
                :valorMinimoSet="0"
                :valorMaximoSet="100000"
                MensajeAyudaSet="Valor del minuto de espera"
                textoAlternativoSet="Valor minuto espera"
                :validatingSet="validando"
              />
            </v-col>
            <v-col cols="6" md="3">
              <frm_num
              ref="minuto_espera_permitido"
                labelSet="Minutos de espera"
                preIconoSet="mdi-clock-outline"
                :valorSet="servicio.minuto_espera_permitido"
                :requeridoSet="true"
                :valorMinimoSet="0"
                :valorMaximoSet="100000"
                MensajeAyudaSet="Cantidad de minutos que no se cobra en espera"
                textoAlternativoSet="Minutos de espera"
                :validatingSet="validando"
              />
            </v-col>
            <v-col cols="6" md="3">
              <frm_num
              ref="valor_minuto_trafico"
                labelSet="Valor minuto trafico"
                preIconoSet="mdi-currency-usd"
                :valorSet="servicio.valor_minuto_trafico"
                :requeridoSet="true"
                :valorMinimoSet="0"
                :valorMaximoSet="100000"
                MensajeAyudaSet="Valor del minuto en trafico/taco"
                textoAlternativoSet="Valor minuto trafico"
                :validatingSet="validando"
              />
            </v-col>
            <v-col cols="6" md="3">
              <frm_num
              ref="minuto_trafico_permitido"
                labelSet="Minutos en tráfico"
                preIconoSet="mdi-clock-outline"
                :valorSet="servicio.minuto_trafico_permitido"
                :requeridoSet="true"
                :valorMinimoSet="0"
                :valorMaximoSet="100000"
                MensajeAyudaSet="Cantidad de minutos que no se cobra por tráfico/taco"
                textoAlternativoSet="Minutos en tráfico"
                :validatingSet="validando"
              />
            </v-col>
            <v-col cols="6" md="3">
              <frm_num
              ref="valor_tag"
                labelSet="Valor TAG"
                preIconoSet="mdi-currency-usd"
                :valorSet="servicio.valor_tag"
                :requeridoSet="true"
                :valorMinimoSet="0"
                :valorMaximoSet="100000"
                MensajeAyudaSet="Valor estándar por TAG"
                textoAlternativoSet="Valor TAG"
                :validatingSet="validando"
              />
            </v-col>
          </v-row>

          <v-row dense class="text-caption pt-3">
            <v-col cols="12" v-if="isMobile">
              <btn_submit
                labelSet="Guardar Servicio"
                :loading="validando"
                append-icon="mdi-content-save-move"
              />
            </v-col>
            <v-col :cols="isMobile ? 12 : 6">
              <btn_cancel
                labelSet="Cancelar"
                @accion="volverListaServicio"
                :loading="validando"
                preIcon="mdi-chevron-left"
              />
            </v-col>
            <v-col cols="6" v-if="!isMobile">
              <btn_submit
                labelSet="Guardar Servicio"
                :loading="validando"
                append-icon="mdi-content-save-move"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <mensajeUsuario ref="mensajeUsuario" />
    <mensajeUsuario ref="mensajeUsuarioOk" @cerrar="volverListaServicio" />
    <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="SalirSistema" />
  </v-container>
</template>

<script>
import {
  enviarJsonPOST,
  paletColor,
  isMobile,
  obtenerJsonGET,
} from "@/helpers/helpers.js";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import frm_text from "@/components/form/textos.vue";
import frm_num from "@/components/form/numero.vue";
import btn_submit from "@/components/form/buttonSubmitform.vue";
import btn_cancel from "@/components/form/buttonCancelform.vue";

export default {
  name: "start",

  data: () => ({
    paletColor: paletColor,
    validForm: false,
    servicio_uuid: null,
    servicio: {
      nombre: null,

    },
    validando: false,
    isMobile: isMobile(),
  }),
  components: {
    mensajeUsuario,
    frm_text,
    frm_num,
    btn_submit,
    btn_cancel,
  },
  mounted: function () {
    this.servicio_uuid = this.$route.params.uuid;
    if(this.servicio_uuid && this.servicio_uuid.length > 10){
      this.obtenerServicio();
    }
  },
  methods: {
    volverMenu: function () {
      this.$router.go(-1);
    },
    obtenerServicio: async function () {
      let param = {
        servicio_uuid: this.servicio_uuid,
      };
      let data = await obtenerJsonGET("/servicio/obtener_servicio/", param);
      if (data.error == "0") {
        this.servicio = data.data;
        this.servicio.porcentaje_comision =
          Math.round(parseFloat(this.servicio.porcentaje_comision), 2) + "";
        this.servicio.porcentaje_descuento =
          Math.round(parseFloat(this.servicio.porcentaje_descuento), 2) + "";
        this.servicio.bajada_bandera =
          Math.round(parseFloat(this.servicio.bajada_bandera), 2) + "";
        this.servicio.valor_kilometro =
          Math.round(parseFloat(this.servicio.valor_kilometro), 2) + "";
        this.servicio.valor_minuto_espera =
          Math.round(parseFloat(this.servicio.valor_minuto_espera), 2) + "";
        this.servicio.minuto_espera_permitido =
          Math.round(parseFloat(this.servicio.minuto_espera_permitido), 2) + "";
        this.servicio.minuto_trafico_permitido =
          Math.round(parseFloat(this.servicio.minuto_trafico_permitido), 2) +
          "";
        this.servicio.valor_minuto_trafico =
          Math.round(parseFloat(this.servicio.valor_minuto_trafico), 2) + "";
        this.servicio.valor_tag =
          Math.round(parseFloat(this.servicio.valor_tag), 2) + "";
        this.servicio.uso_conductor = parseInt(this.servicio.uso_conductor);
        console.log(this.servicio);
        if (this.servicio.uso_conductor == 0) {
          this.$refs.mensajeUsuario.mostrar(
            "Este servicio no puede ser modificado por usted. Pongase en contacto con su administrador."
          );
        }
      } else {
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      }
    },
    GuardarServicio: async function () {
      this.validando = true;
      let { valid } = await this.$refs.form_ingreso.validate();
      if (valid) {
        let fd = new FormData();
        fd.append("servicio_uuid", this.servicio_uuid);
        fd.append("porcentaje_comision", this.$refs.porcentaje_comision.valor);
        fd.append("porcentaje_comision", this.$refs.porcentaje_comision.valor);
        fd.append("porcentaje_descuento", this.$refs.porcentaje_descuento.valor);
        fd.append("bajada_bandera", this.$refs.bajada_bandera.valor);
        fd.append("valor_kilometro", this.$refs.valor_kilometro.valor);
        fd.append("valor_minuto_espera", this.$refs.valor_minuto_espera.valor);
        fd.append("minuto_espera_permitido", this.$refs.minuto_espera_permitido.valor);
        fd.append("valor_minuto_trafico", this.$refs.valor_minuto_trafico.valor);
        fd.append("minuto_trafico_permitido", this.$refs.minuto_trafico_permitido.valor);
        fd.append("valor_tag", this.$refs.valor_tag.valor);
        let data = await enviarJsonPOST("/servicio/guardar_servicio/", fd);
        if (data.error == 0) {
          this.$refs.mensajeUsuarioOk.mostrar(data.message, "Información");
        } else {
          if (data.error == 1000) {
            this.$refs.mensajeUsuarioSalir.mostrar(data.message);
          } else {
            this.$refs.mensajeUsuario.mostrar(data.message);
          }
        }
      } else {
        this.$refs.mensajeUsuario.mostrar(
          "Debe ingresar los datos requeridos",
          "Advertencia"
        );
      }
      this.validando = false;
    },
    SalirSistema: function () {
      this.$router.replace("/");
    },
    volverListaServicio: function () {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>