<template>
  <v-container class="justify-center align-center">
    <div
      class="slide-button"
      ref="slideArea"
      :style="{ backgroundColor: slideColor }"
      @touchstart="startSlide"
      @touchmove="handleSlide"
      @touchend="endSlide"
      v-if="isMobile()"
    >
      <span class="slide-text">{{ slideText }}</span>
      <div
        class="slider"
        :style="{ transform: `translateX(${sliderPosition}px)` }"
      >
        <v-icon large>mdi-arrow-right</v-icon>
      </div>
    </div>
    <v-row dense v-if="!isMobile()">
      <v-col cols="6" class="d-flex justify-center align-center">
        <v-btn
          class="rounded-pill"
          :color="colorAccionIzquierdaLocal"
          elevation="4"
          outlined
          block
          @click="accionIzquierda"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          {{ textoIzquierdaLocal }}
        </v-btn>
      </v-col>
      <v-col cols="6" class="d-flex justify-center align-center">
        <v-btn
          class="rounded-pill"
          :color="colorAccionDerechaLocal"
          elevation="4"
          outlined
          block
          @click="accionDerecha"
        >
          <v-icon right>mdi-arrow-right</v-icon>
          {{ textoDerechaLocal }}
        </v-btn>
      </v-col>
    </v-row>

    <v-alert v-if="action" type="info" class="mt-5">
      {{ action }}
    </v-alert>
  </v-container>
</template>
  
  <script>
import { isMobile,paletColor } from "@/helpers/helpers";
export default {
  data() {
    return {
      textoIzquierdaLocal: "Rechazar",
      textoDerechaLocal: "Aceptar",
      colorEsperaAccionLocal: paletColor.buttons.deslizar.background,
      colorAccionDerechaLocal: paletColor.buttons.siguiente.background,
      colorAccionIzquierdaLocal: paletColor.buttons.problem.background,

      sliderPosition: 0,
      startX: 0,
      action: null,
      slideColor: "", // Color inicial del fondo
      slideText: "", // Texto del botón (Aceptar/Rechazar)
      isMobile: isMobile,
    };
  },
  props: {
    textoIzquierda: String,
    textoDerecha: String,
  },
  watch: {
    textoIzquierda: function (val) {
      this.textoIzquierdaLocal = val;
    },
    textoDerecha: function (val) {
      this.textoDerechaLocal = val;
    },
    colorEsperaAccion: function (val) {
      this.colorEsperaAccionLocal = val;
    },
  },
  mounted: function () {
    if (this.textoIzquierda) {
      this.textoIzquierdaLocal = this.textoIzquierda;
    }
    if (this.textoDerecha) {
      this.textoDerechaLocal = this.textoDerecha;
    }
    if (this.colorEsperaAccion) {
      this.colorEsperaAccionLocal = this.colorEsperaAccion;
    }
    this.slideColor = this.colorEsperaAccionLocal;
    this.slideText = this.textoDerechaLocal;
  },
  methods: {
    startSlide(event) {
      this.startX = event.touches[0].clientX;
      this.slideText = this.textoDerechaLocal; // Limpia el texto al iniciar el deslizamiento
    },
    handleSlide(event) {
      const currentX = event.touches[0].clientX;
      const deltaX = currentX - this.startX;
      this.sliderPosition = Math.max(-100, Math.min(100, deltaX));

      // Cambiar color de fondo y texto según la dirección del deslizamiento
      if (this.sliderPosition > 0) {
        this.slideColor = this.colorAccionDerechaLocal; // Verde
        this.slideText = this.textoDerechaLocal;
      } else if (this.sliderPosition < 0) {
        this.slideColor = this.colorAccionIzquierdaLocal; // Rojo
        this.slideText = this.textoIzquierdaLocal;
      } else {
        this.slideColor = this.colorEsperaAccionLocal; // Fondo inicial
        this.slideText = this.textoDerechaLocal;
      }
    },
    endSlide() {
      if (this.sliderPosition > 85) {
        this.accionDerecha();
      } else if (this.sliderPosition < -85) {
        this.accionIzquierda();
      } else {
        this.action = null;
      }
      this.sliderPosition = 0; // Reinicia la posición del deslizador
      this.slideColor = this.colorEsperaAccionLocal; // Reinicia el color
      this.slideText = this.textoDerechaLocal; // Reinicia el texto
    },
    accionDerecha: function(){
        this.$emit("derecha");
    },
    accionIzquierda: function(){
        this.$emit("izquierda");
    }
  },
};
</script>
  
  <style>
.slide-button {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider {
  position: absolute;
  top: 0;
  left: 40%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  background-color: #728aca;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  transition: transform 0.3s ease;
  z-index: 2;
}
.slide-text {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  z-index: 1;
}
</style>