<template>
  <v-text-field
    :clearable="clearable"
    :label="label"
    variant="outlined"
    :autofocus="autofocus"
    ref="texto"
    v-model="valor"
    density="compact"
    autocomplete="false"
    :prepend-inner-icon="icono"
    :placeholder="textoAlternativo"
    :hint="MensajeAyuda"
    :readonly="readonly"
    :loading="validating"
    :disabled="validating"
    :rules="[
      this.requerido && rules.requerido ? rules.requerido : null,
      this.valor != null && this.minLength > this.valor.length
        ? 'Mínimo ' + this.separadorMiles(this.minLength) + ' digitos.'
        : null,
      this.valor != null && this.maxLength < this.valor.length
        ? 'Máximo ' + this.separadorMiles(this.maxLength) + ' digitos.'
        : null,
        this.valor != null && !this.validarFormatoRUT(this.valor)
        ? 'El RUT ingresado no tiene el formato válido'
        : null,
        this.valor != null && !this.validarRUT(this.valor) ? 'El RUT ingresado no es válido' : null,
    ]"
    @blur="perderFoco"
  ></v-text-field>
</template>
  
  <script>
export default {
  data: () => ({
    clearable: true,
    valor: null,
    validating: false,
    label: "RUT",
    icono: 'mdi-card-account-details-outline',
    textoAlternativo: "99999999-K",
    MensajeAyuda: null,
    autofocus: false,
    requerido: false,
    readonly: false,
    minLength: 8,
    maxLength: 11,
    rules: {
      requerido(v) {
        if (!v) {
          return "Es requerido.";
        }
      },
    },
  }),
  props: {
    valorSet: String,
    validatingSet: Boolean,
    labelSet: String,
    autofocusSet: Boolean,
    requeridoSet: Boolean,
    readonlySet: Boolean,
    iconoSet: String,
    textoAlternativoSet: String,
    MensajeAyudaSet: String,
    focus: Boolean,
    idExterno: Number,
  },
  watch: {
    valorSet: function (val) {
      this.valor = val;
    },
    validatingSet: function (val) {
      this.validating = val;
    },
    labelSet: function (val) {
      this.label = val;
    },
    autofocusSet: function (val) {
      this.autofocus = val;
    },
    requeridoSet: function (val) {
      this.requerido = val;
    },
    readonlySet: function (val) {
      this.readonly = val;
      if (this.readonly) {
        this.clearable = false;
      } else {
        this.clearable = false;
      }
    },
    iconoSet: function (val) {
      this.icono = val;
    },
    textoAlternativoSet: function (val) {
      this.textoAlternativo = val;
    },
    MensajeAyudaSet: function (val) {
      this.MensajeAyuda = val;
    },
    focus: function (val) {
      if (val) {
        this.$refs.Email.focus();
      }
    },
    valor: function (val) {
      this.$emit("SetValor", val);
    },
  },
  mounted: function () {
    if (this.valorSet) {
      this.valor = this.valorSet;
    }
    if (this.validatingSet) {
      this.validating = this.validatingSet;
    }
    if (this.labelSet) {
      this.label = this.labelSet;
    }
    if (this.autofocusSet) {
      this.autofocus = this.autofocusSet;
    }
    if (this.requeridoSet) {
      this.requerido = this.requeridoSet;
    }
    if (this.readonlySet) {
      this.readonly = this.readonlySet;
      if (this.readonly) {
        this.clearable = false;
      } else {
        this.clearable = true;
      }
    }
    if (this.iconoSet) {
      this.icono = this.iconoSet;
    }
    if (this.textoAlternativoSet) {
      this.textoAlternativo = this.textoAlternativoSet;
    }
    if (this.MensajeAyudaSet) {
      this.MensajeAyuda = this.MensajeAyudaSet;
    }
  },
  methods: {
    perderFoco: function (obj) {
      this.$emit("perderFoco", this.valor, this.idExterno);
    },

    validarRUT: function (rutCompleto) {

      if (!rutCompleto || typeof rutCompleto !== "string") {
        return false; // Si es null, undefined o no es un string, retorna false
      }
      // Eliminar puntos y convertir todo a mayúsculas
      const rutLimpio = rutCompleto.replace(/\./g, "").toUpperCase();

      // Separar el número del dígito verificador
      const [numero, dv] = rutLimpio.split("-");

      // Validar formato
      if (!numero || !dv || isNaN(numero)) {
        return false;
      }

      // Convertir número a un array de dígitos
      const rutArray = numero.split("").reverse().map(Number);

      // Calcular dígito verificador usando el módulo 11
      let suma = 0;
      let multiplicador = 2;

      for (let digito of rutArray) {
        suma += digito * multiplicador;
        multiplicador = multiplicador === 7 ? 2 : multiplicador + 1;
      }

      const resto = suma % 11;
      const dvCalculado =
        11 - resto === 10 ? "K" : 11 - resto === 11 ? "0" : String(11 - resto);

      // Comparar dígito verificador calculado con el proporcionado
      return dv === dvCalculado;
    },
    validarFormatoRUT: function (rut) {
      // Expresión regular para validar el formato del RUT chileno
      const rutRegex = /^[0-9]{7,9}-[0-9Kk]$/;

      // Retorna true si el formato cumple con la expresión regular
      return rutRegex.test(rut);
    },

    separadorMiles: function (numero) {
      // Validar que el número sea un valor numérico
      if (isNaN(numero)) {
        return "0";
      }
      numero = parseFloat(numero);
      // Usar toLocaleString para formatear el número con separador de miles
      return numero.toLocaleString("es-CL");
    },
  },
};
</script>
  
  <style>
</style>