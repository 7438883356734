<template>
  <v-container> Confirmando Ingreso... </v-container>
</template>

<script>
import {enviarJsonPOST,obtenerDominio} from "@/helpers/helpers.js";

export default {
  name: "App",

  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL,
    debugMode: process.env.VUE_APP_DEBUG_MODE === "true", // Convierte a booleano si es necesario
    urlExit: "https://"+obtenerDominio().dominio,
    usuario: {
      nombre: null,
      Token: null,
    },
  }),
  mounted: async function () {
    if (typeof this.$route.params == "object") {
      if (Object.keys(this.$route.params).length == 2) {
        const usuario = {
          nombre: this.$route.params.usuario,
          Token: this.$route.params.token,
          FechaUltimoLlamado: new Date(), // Fecha en formato ISO
        };
        
        let fd = new FormData();
        fd.append("tokenID", usuario.Token);
        let data = await enviarJsonPOST("/usuario/valida_token/", fd);

        if(data){
          if(data.error != 0){
            window.location.replace(process.env.VUE_APP_LOGIN);
          }else{
            sessionStorage.setItem("usuarioDriverIn", JSON.stringify(usuario));
            this.$router.push("/");
          }
        }else{
          window.location.replace(process.env.VUE_APP_LOGIN);
        }

      } else {
        window.location.replace(process.env.VUE_APP_LOGIN);
      }
    } else {
      window.location.replace(process.env.VUE_APP_LOGIN); 
    }
  },
};
</script>
<style>
</style>