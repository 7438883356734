<template>
  <div id="map" :style="{ width: '100%', height: '400px' }"></div>
</template>
  
  <script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  props: {
    initialLatLng: {
      type: Object,
      required: true, // Debe contener { lat: Number, lng: Number }
    },
  },
  data() {
    return {
      origenIcon: L.icon({
        iconUrl: "/img/iconos/IconoMapaLocations.gif",
        iconSize: [25, 40],
        iconAnchor: [12.5, 40],
      }),
      map: null, // Referencia al mapa de Leaflet
      marker: null, // Referencia al marcador actual
    };
  },
  watch: {
    // Detecta cambios en initialLatLng
    initialLatLng: {
      immediate: true, // Ejecuta el watcher inmediatamente al montar el componente
      handler(newLatLng) {
        if (this.map) {
          // Actualiza la vista del mapa
          this.map.setView([newLatLng.lat, newLatLng.lng], 17);

          // Si existe un marcador previo, actualiza su posición
          if (this.marker) {
            this.marker.setLatLng([newLatLng.lat, newLatLng.lng]);
          } else {
            // Si no existe, crea un nuevo marcador
            this.marker = L.marker([newLatLng.lat, newLatLng.lng], {
              icon: this.origenIcon,
            }).addTo(this.map);
          }
        }
      },
    },
  },
  mounted() {
    this.initializeMap();
  },
  methods: {
    initializeMap() {
      // Inicializar el mapa si aún no existe
      this.map = L.map("map").setView(
        [this.initialLatLng.lat, this.initialLatLng.lng],
        17
      );

      // Agregar capa base de OpenStreetMap
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
      }).addTo(this.map);

      // Crear marcador inicial
      this.marker = L.marker([this.initialLatLng.lat, this.initialLatLng.lng], {
        icon: this.origenIcon,
      }).addTo(this.map);

      // Agregar evento de clic para añadir un marcador en la posición seleccionada
      this.map.on("click", this.onMapClick);
    },
    onMapClick(event) {
        console.log(event);
      const { lat, lng } = event.latlng;

      // Si existe un marcador previo, actualizarlo
      if (this.marker) {
        this.marker.setLatLng([lat, lng]);
      } else {
        // Si no existe, crea uno nuevo
        this.marker = L.marker([lat, lng], { icon: this.origenIcon }).addTo(
          this.map
        );
      }

      // Llamar a la función de manejo con las coordenadas
      this.handleMapClick(lat, lng);
    },
    handleMapClick(lat, lng) {
      // Emitir las coordenadas seleccionadas
      this.$emit("setLatLng", { lat, lng });
      console.log(`Latitud: ${lat}, Longitud: ${lng}`);
    },
  },
};
</script>
  
  <style scoped>
#map {
  border: 1px solid #ccc;
  border-radius: 15px;
  height: 50vh; /* Por ejemplo, ocupar toda la pantalla */
  width: 100%; /* Ancho completo */
}
.leaflet-control-attribution {
  display: none !important;
}
.custom-tooltip {
  background: #333;
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
}
</style>
  