<template>
  <v-container>
    <v-form
      ref="form_ingreso"
      v-model="validForm"
      @submit.prevent="ingresarDriverIn"
    >
      <v-card
        class="mx-auto"
        elevation-6
        :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
        max-width="90%"
      >
        <v-card-title style="height: 80px">
          <v-img :src="obtenerLogoApp()" height="50px"></v-img>
        </v-card-title>
        <v-card-text
          :style="'background-color: ' + paletColor.colorNeutro + ';'"
        >
          <div v-if="pantalla == 1">
            <v-row dense no-gutters class="text-caption">
              <!--v-col cols="12" md="6">
              <v-img src="/img/start/conductor.png" width="100%"></v-img>
            </v-col-->
              <v-col cols="12" class="pa-md-6">
                <v-row dense class="text-caption">
                  <v-col cols="12">
                    <span class="text-h5">Registro Conductor</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <cmp_rut
                      v-model="rut"
                      :requeridoSet="true"
                      :validatingSet="validating"
                    ></cmp_rut>
                  </v-col>
                  <v-col cols="12" md="6">
                    <cmp_email
                      v-model="email"
                      :requeridoSet="true"
                      :validatingSet="validating"
                    ></cmp_email>
                  </v-col>
                  <v-col cols="12" md="6">
                    <cmp_telefono
                      v-model="telefono"
                      :requeridoSet="true"
                      :validatingSet="validating"
                    ></cmp_telefono>
                  </v-col>
                  <v-col cols="12" md="6">
                    <cmp_text
                      v-model="nombres"
                      labelSet="Nombres"
                      iconoSet="mdi-account-tie"
                      :maxLengthSet="50"
                      :minLengthSet="3"
                      :requeridoSet="true"
                      :validatingSet="validating"
                    ></cmp_text>
                  </v-col>

                  <v-col cols="12" md="6">
                    <cmp_text
                      v-model="app"
                      labelSet="Apellido Paterno"
                      iconoSet="mdi-account-details"
                      :maxLengthSet="50"
                      :minLengthSet="5"
                      :requeridoSet="true"
                      :validatingSet="validating"
                    ></cmp_text>
                  </v-col>
                  <v-col cols="12" md="6">
                    <cmp_text
                      v-model="apm"
                      labelSet="Apellido Materno"
                      iconoSet="mdi-account-details"
                      :maxLengthSet="50"
                      :minLengthSet="5"
                      :requeridoSet="true"
                      :validatingSet="validating"
                    ></cmp_text>
                  </v-col>
                  <v-col cols="12">
                    <cmp_check
                    ref="licencias"
                    v_model="licen"
                      labelSet="Licencia"
                      :opciones="licencias"
                      :inlineSet="true"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <cmp_patente
                      ref="patente"
                      v-model="patente"
                      :requeridoSet="true"
                      :validatingSet="validating"
                      @perderFoco="buscarVehiculo"
                    ></cmp_patente>
                  </v-col>
                  <v-col cols="12" md="6">
                    <cmp_text
                      v-model="marca"
                      labelSet="Marca"
                      :maxLengthSet="50"
                      :minLengthSet="3"
                      :requeridoSet="true"
                      :validatingSet="validating"
                    ></cmp_text>
                  </v-col>
                  <v-col cols="12" md="6">
                    <cmp_text
                      v-model="modelo"
                      labelSet="Modelo"
                      :maxLengthSet="50"
                      :minLengthSet="3"
                      :requeridoSet="true"
                      :validatingSet="validating"
                    ></cmp_text>
                  </v-col>
                  <v-col cols="12" md="6">
                    <cmp_numero
                      v-model="agno"
                      labelSet="Año"
                      :valorMinimoSet="1970"
                      :valorMaximoSet="2025"
                      :requeridoSet="true"
                      :validatingSet="validating"
                    ></cmp_numero>
                  </v-col>
                </v-row>
                <v-row>
                  <!--
                  Esto sólo se muestra si es mobile
                  -->
                  <v-col cols="12" v-if="isMobile">
                    <cmp_submit
                      labelSet="Regtistrar"
                      :validating="validating"
                    />
                  </v-col>
                  <v-col :cols="isMobile ? 12 : 6">
                    <cmp_cancel
                      labelSet="Volver"
                      :validating="validating"
                      @cerrar="volverLogin"
                    />
                  </v-col>
                  <!--
                  Esto sólo se muestra si es aplicación
                  -->
                  <v-col cols="6" v-if="!isMobile">
                    <cmp_submit
                      labelSet="Regtistrar"
                      :validating="validating"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div v-if="pantalla == 2" class="pa-6">
            Su registro ha sido completado, por favor revise su cuenta de correo para validarla.<br><br>
            Es importante la validación, dado que es la forma en que se confirma que sea un correo válido y accesible por ustes<br><br>
            ¡Nosotros Cuidamos tu seguridad!<br><br>
            <cmp_cancel
                      labelSet="Validar correo"
                      :validating="validating"
                      @cerrar="ValidarCorreo"
                    />
        
        </div>


        </v-card-text>
        <v-card-action class="pa-1">
          <v-row> </v-row>
        </v-card-action>
      </v-card>
    </v-form>
  </v-container>
  <mensajeUsuario ref="mensajeUsuario" />
</template>
    
    <script>
import { enviarJsonPOST, paletColor, isMobile, obtenerLogoApp } from "@/helpers/helpers";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import cmp_rut from "@/components/form/rut.vue";
import cmp_patente from "@/components/form/patente.vue";
import cmp_telefono from "@/components/form/telefono.vue";
import cmp_text from "@/components/form/textos.vue";
import cmp_numero from "@/components/form/numero.vue";
import cmp_email from "@/components/form/email.vue";
import cmp_password from "@/components/form/password.vue";
import cmp_cancel from "@/components/form/buttonCancelform.vue";
import cmp_submit from "@/components/form/buttonSubmitform.vue";
import cmp_check from "@/components/form/checkBoxMultiple.vue";
export default {
  name: "Login",
  data: () => ({
    validForm: null,
    validating: null,
    pantalla: 1,
    rut: null,
    telefono: null,
    nombres: null,
    app: null,
    apm: null,
    nombre: null,
    email: null,
    clave: null,
    patente: null,
    marca: null,
    modelo: null,
    agno: null,
    licen: [],
    licencias: [
      { label: "B", valor: "B" },
      { label: "A1", valor: "A1" },
      { label: "A2", valor: "A2" },
      { label: "A4", valor: "A4" },
    ],
    paletColor: paletColor,
    isMobile: isMobile(),
    obtenerLogoApp: obtenerLogoApp,
  }),
  components: {
    cmp_rut,
    cmp_patente,
    cmp_telefono,
    cmp_text,
    cmp_numero,
    cmp_email,
    cmp_password,
    cmp_check,
    cmp_cancel,
    cmp_submit,

    mensajeUsuario,
  },
  props: {},
  methods: {
    volverLogin: function () {
      window.location.replace("/");
    },
    buscarVehiculo: async function () {
      if (this.$refs.patente.isValid()) {
        let fd = new FormData();
        fd.append("patente", this.$refs.patente.valor);
        let data = await enviarJsonPOST("/vehiculo/obtenervehiculo/", fd);
        if (data) {
          if (data.error == 0) {
            this.modelo = data.data.modelo
            this.marca = data.data.marca
            this.agno = data.data.agno
          } else if (data.error != 1) {
            this.$refs.mensajeUsuario.mostrar(data.message);
          }
        } else {
          this.$refs.mensajeUsuario.mostrar(
            "ocurrio un problema al intentar validar al usuario."
          );
        }
      }
    },
    ValidarCorreo: function(){
        this.$router.replace("/validaregistro");
    },
    ingresarDriverIn: async function () {
      const { valid } = await this.$refs.form_ingreso.validate();
      this.validating = true;
      if (valid) {
        let fd = new FormData();
        fd.append("rut", this.rut);
        fd.append("email", this.email);
        fd.append("telefono", this.telefono);
        fd.append("nombres", this.nombres);
        fd.append("app", this.app);
        fd.append("apm", this.apm);
        let l ="";
        for(let x=0;x<this.$refs.licencias.valor.length;x++){
            l += (l?" - ":"") + this.$refs.licencias.valor[x];
        }
        fd.append("licencias", l);
        fd.append("patente", this.patente);
        fd.append("marca", this.marca);
        fd.append("modelo", this.modelo);
        fd.append("agno", this.agno);
        let data = await enviarJsonPOST(
          "/usuario/registrar_cuenta_conductor/",
          fd
        );
        if (data) {
          if (data.error == 0) {
            this.pantalla = 2;
          } else {
            this.$refs.mensajeUsuario.mostrar(data.message);
          }
        } else {
          this.$refs.mensajeUsuario.mostrar(
            "ocurrio un problema al intentar validar al usuario."
          );
        }
      } else {
        this.$refs.mensajeUsuario.mostrar("Debe ingrersar todos los datos");
      }
      this.validating = false;
    },
  },
};
</script>
    
    <style>
</style>