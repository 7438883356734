<template>
  <v-text-field
    :clearable="clearable"
    :label="label"
    variant="outlined"
    :autofocus="autofocus"
    ref="texto"
    v-model="valor"
    density="compact"
    autocomplete="false"
    :prepend-inner-icon="icono"
    :placeholder="textoAlternativo"
    :hint="MensajeAyuda"
    :readonly="readonly"
    :loading="validating"
    :disabled="validating"
    :maxLength="maxLength"
    :rules="[
      this.requerido && rules.requerido ? rules.requerido : null,
      this.valor != null && this.minLength > this.valor.length
        ? 'Mínimo ' + this.separadorMiles(this.minLength) + ' digitos.'
        : null,
      this.valor != null && this.maxLength < this.valor.length
        ? 'Máximo ' + this.separadorMiles(this.maxLength) + ' digitos.'
        : null,
        this.valor != null && !this.validarFormatoTelefono(this.valor).valido ? 'El formato de teléfono no es válido' : null
    ]"
    @blur="perderFoco"
  ></v-text-field>
</template>
  
  <script>
export default {
  data: () => ({
    clearable: true,
    valor: null,
    validating: false,
    label: "Teléfono",
    icono: 'mdi-phone',
    textoAlternativo: '+56911111111',
    MensajeAyuda: null,
    autofocus: false,
    requerido: false,
    readonly: false,
    minLength: 10,
    maxLength: 13,
    rules: {
      requerido(v) {
        if (!v) {
          return "Es requerido.";
        }
      },
    },
  }),
  props: {
    valorSet: String,
    validatingSet: Boolean,
    labelSet: String,
    autofocusSet: Boolean,
    requeridoSet: Boolean,
    readonlySet: Boolean,
    minLengthSet: Number,
    maxLengthSet: Number,
    iconoSet: String,
    textoAlternativoSet: String,
    MensajeAyudaSet: String,
    focus: Boolean,
    idExterno: Number,
  },
  watch: {
    valorSet: function (val) {
      this.valor = val;
    },
    validatingSet: function (val) {
      this.validating = val;
    },
    labelSet: function (val) {
      this.label = val;
    },
    autofocusSet: function (val) {
      this.autofocus = val;
    },
    requeridoSet: function (val) {
      this.requerido = val;
    },
    readonlySet: function (val) {
      this.readonly = val;
      if (this.readonly) {
        this.clearable = false;
      } else {
        this.clearable = false;
      }
    },
    minLengthSet: function (val) {
      this.minLengthSet = val;
    },
    maxLengthSet: function (val) {
      this.maxLength = val;
    },
    iconoSet: function (val) {
      this.icono = val;
    },
    textoAlternativoSet: function (val) {
      this.textoAlternativo = val;
    },
    MensajeAyudaSet: function (val) {
      this.MensajeAyuda = val;
    },
    focus: function (val) {
      if (val) {
        this.$refs.Email.focus();
      }
    },
    valor: function (val) {
      this.$emit("SetValor", val);
    },
  },
  mounted: function () {
    if (this.valorSet) {
      this.valor = this.valorSet;
    }
    if (this.validatingSet) {
      this.validating = this.validatingSet;
    }
    if (this.labelSet) {
      this.label = this.labelSet;
    }
    if (this.autofocusSet) {
      this.autofocus = this.autofocusSet;
    }
    if (this.requeridoSet) {
      this.requerido = this.requeridoSet;
    }
    if (this.readonlySet) {
      this.readonly = this.readonlySet;
      if (this.readonly) {
        this.clearable = false;
      } else {
        this.clearable = true;
      }
    }
    if (this.minLengthSet) {
      this.minLength = this.minLengthSet;
    }
    if (this.maxLengthSet) {
      this.maxLength = this.maxLengthSet;
    }
    if (this.iconoSet) {
      this.icono = this.iconoSet;
    }
    if (this.textoAlternativoSet) {
      this.textoAlternativo = this.textoAlternativoSet;
    }
    if (this.MensajeAyudaSet) {
      this.MensajeAyuda = this.MensajeAyudaSet;
    }
  },
  methods: {
    perderFoco: function (obj) {
      this.$emit("perderFoco", this.valor, this.idExterno);
    },

    separadorMiles: function (numero) {
      // Validar que el número sea un valor numérico
      if (isNaN(numero)) {
        return "0";
      }
      numero = parseFloat(numero);
      // Usar toLocaleString para formatear el número con separador de miles
      return numero.toLocaleString("es-CL");
    },
    validarFormatoTelefono: function (telefono, soloChile = false) {
      // Validar que 'telefono' sea una cadena válida
      if (!telefono || typeof telefono !== "string") {
        return { valido: false, pais: null }; // Si no es válido, retorna false
      }

      // Expresión regular básica: + seguido de 10 o más dígitos
      const telefonoRegex = /^\+[0-9]{10,13}$/;
      if (!telefonoRegex.test(telefono)) {
        return { valido: false, pais: null }; // Retorna false si no cumple el formato básico
      }

      // Extraer el código de país (los primeros 2-3 dígitos después del +)
      const codigoPais = telefono.slice(1, 3); // Ejemplo: "+56xxxx" => "56"
      const codigoPaisLargo = telefono.slice(1, 4); // Para países de 3 dígitos como "+598"

      // Objeto con códigos de países válidos y sus nombres
      const codigosPaisesValidos = {
        1: "Estados Unidos/Canadá",
        44: "Reino Unido",
        49: "Alemania",
        33: "Francia",
        34: "España",
        55: "Brasil",
        56: "Chile",
        57: "Colombia",
        58: "Venezuela",
        60: "Malasia",
        61: "Australia",
        62: "Indonesia",
        63: "Filipinas",
        81: "Japón",
        82: "Corea del Sur",
        86: "China",
        91: "India",
        98: "Irán",
        212: "Marruecos",
        213: "Argelia",
        216: "Túnez",
        598: "Uruguay",
      };

      // Si solo se permite Chile
      if (soloChile) {
        if (codigoPais === "56") {
          return { valido: true, pais: "Chile" };
        }
        return { valido: false, pais: null };
      }

      // Validar si el código de país está en el objeto
      if (codigosPaisesValidos[codigoPais]) {
        return { valido: true, pais: codigosPaisesValidos[codigoPais] };
      } else if (codigosPaisesValidos[codigoPaisLargo]) {
        return { valido: true, pais: codigosPaisesValidos[codigoPaisLargo] };
      }

      return { valido: false, pais: null }; // Código inválido
    },
  },
};
</script>
  
  <style>
</style>