<template>
  <v-combobox
    :clearable="clearable && !readonly"
    ref="multicombobox"
    :autofocus="autofocus"
    :label="label"
    v-model="valor"
    :items="items"
    variant="outlined"
    density="compact"
    autocomplete="false"
    :loading="validating"
    :disabled="validating"
    :prepend-inner-icon="preIcono"
    :append-inner-icon="posIcon"
    :maxLength="maxLength"   
    :minLength="minLength"
    :placeholder="textoAlternativo"
    :hint="MensajeAyuda"
    :readonly="readonly"
    hide-selected
    :rules="[this.requerido && rules.requerido ? rules.requerido : null,
      this.valor != null && this.minLength > this.valor.length
        ? 'Mínimo ' + this.separadorMiles(this.minLength) + ' digitos.'
        : null,
      this.valor != null && this.maxLength < this.valor.length
        ? 'Máximo ' + separadorMiles(this.maxLength) + ' digitos.'
        : null
    ]"
    :item-value="itemValue"
    :item-title="itemTitle"
    @blur="ChangeValue"
    @update:modelValue="SelectValue"
  >
  <template #item="{ item, props }">
        <v-list-item v-bind="props">
            <template #title>
                <span class="text-caption"><v-avatar class="bg-cyan-darken-4 text-uppercase text-caption" start>{{
            item.title.slice(0, 1)
          }}</v-avatar> {{item.title}}</span>
            </template>
        </v-list-item>
    </template>
    <template v-slot:selection="data">
      <v-chip
        :key="JSON.stringify(data.item)"
        v-bind="data.attrs"
        :disabled="data.disabled"
        :model-value="data.selected"
        size="small"
        @click:close="data.parent.selectItem(data.item)"
      >
        <template v-slot:prepend>
          <v-avatar class="bg-cyan-darken-4 text-uppercase" start>{{
            data.item.title.slice(0, 1)
          }}</v-avatar>
        </template>
        {{ data.item.title }}
      </v-chip>
    </template>
  </v-combobox>
</template>
  
  <script>
import numeroVue from './numero.vue';

export default {
  data: () => ({
    clearable: true,
    valor: null,
    readonly: false,
    items: [],
    itemValue: "cod",
    itemTitle: "desc",
    valorMinimo: null,
    valorMaximo: null,
    textoAlternativo: null,
    MensajeAyuda: null,
    label: "MultiComboBox",
    maxLength: 100,
    minLength: 0,
    validating: null,
    autofocus: false,
    requerido: false,
    preIcono: null,
    posIcon: null,
    rules: {
      requerido(v) {
        if (!v) {
          return "Es requerido.";
        }else if(v.length <=0){
          return "Es requerido.";
        }
      },
    },
  }),
  watch: {
    itemsSet: function(val){
      if(typeof(val) == 'object'){
        this.items = val;
      }
    },
    valorSet: function (val) {
      let v = val;
      if(typeof v == 'string'){
        v = v.replaceAll("$", "");
        v = v.replaceAll(".", "");
      }
      this.valor = v;
    },
    valorMinimoSet: function (val) {
      this.valorMinimo = val;
    },
    valorMaximoSet: function (val) {
      this.valorMaximo = val;
    },
    labelSet: function (val) {
      this.label = val;
    },
    validatingSet: function (val) {
      this.validating = val;
    },
    textoAlternativoSet: function (val) {
      this.textoAlternativo = val;
    },
    MensajeAyudaSet: function (val) {
      this.textoAlternativo = val;
    },
    maxLengthSet: function (val) {
      this.maxLength = val;
    },
    minLengthSet: function (val) {
      this.minLength = val;
    },
    autofocusSet: function (val) {
      if (val) {
        this.$refs.numero.focus();
      }
    },
    requeridoSet: function (val) {
      this.requerido = val;
    },
    valor: function (val) {
      this.$emit("SetValor", val);
    },
    preIconoSet: function (val) {
      this.preIcono = val;
    },
    posIconSet: function (val) {
      this.preIcono = val;
    },
    readonlySet: function(val){
      this.readonly = val;
      if(this.readonly){
        this.clearable =false;
      }else{
        this.clearable =false;
      }
    }
  },
  props: {
    labelSet: String,
    valorSet: Number,
    valorMinimoSet: Number,
    valorMaximoSet: Number,
    textoAlternativoSet: String,
    MensajeAyudaSet: String,
    validatingSet: Boolean,
    autofocusSet: Boolean,
    requeridoSet: Boolean,
    preIconoSet: String,
    posIconSet: String,
    itemsSet: Array,
    readonlySet: Boolean,
    maxLengthSet: Number,
    minLengthSet: Number,
  },
  mounted: async function () {
    if(this.itemsSet){
      if(typeof(this.itemsSet) == 'object'){
        this.items = this.itemsSet;
      }
    }
    if (this.valorSet) {
      let v = this.valorSet;
      if(typeof v == 'string'){
        v = v.replaceAll("$", "");
        v = v.replaceAll(".", "");
      }
      this.valor = v;
    }
    if (this.valorMinimoSet) {
      this.valorMinimo = this.valorMinimoSet + "";
      this.valorMinimo = this.valorMinimo.replaceAll("$", "");
      this.valorMinimo = this.valorMinimo.replaceAll(".", "");
    }
    if (this.valorMaximoSet) {
      this.valorMaximo = this.valorMaximoSet + "";
      this.valorMaximo = this.valorMaximo.replaceAll("$", "");
      this.valorMaximo = this.valorMaximo.replaceAll(".", "");
    }
    if (this.labelSet) {
      this.label = this.labelSet;
    }
    if (this.validatingSet) {
      this.validating = this.validatingSet;
    }
    if (this.autofocusSet) {
      this.autofocus = this.autofocusSet;
    }
    if (this.textoAlternativoSet) {
      this.textoAlternativo = this.textoAlternativoSet;
    }
    if (this.MensajeAyudaSet) {
      this.MensajeAyuda = this.MensajeAyudaSet;
    }
    if (this.maxLengthSet) {
      this.maxLength = this.maxLengthSet;
    }
    if (this.minLengthSet) {
      this.minLength = this.minLengthSet;
    }
    if (this.requeridoSet) {
      this.requerido = this.requeridoSet;
    }
    if (this.preIconoSet) {
      this.preIcono = this.preIconoSet;
    }
    if (this.posIconSet) {
      this.posIcon = this.posIconSet;
    }
    if(this.readonlySet){
      this.readonly = this.readonlySet;
      if(this.readonly){
        this.clearable =false;
      }else{
        this.clearable =false;
      }
    }
  },
  methods: {
    
    separadorMiles: function (numero) {
      // Validar que el número sea un valor numérico
      if (isNaN(numero)) {
        return "0";
      }
      numero = parseFloat(numero);
      // Usar toLocaleString para formatear el número con separador de miles
      return numero.toLocaleString("es-CL");
    },
    ChangeValue: function(val){
      this.$emit("ChangeValue",this.valor);
    },
    SelectValue: function(val){
      this.$emit("SelectValue",this.valor);
    },
  },
};
</script>
  
  <style>
</style>