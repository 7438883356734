<template>
  <v-container :class="isMobile ? 'px-0 py-0' : ''">
    <div class="text-h6">Seleccione ruta</div>
    <span v-if="data">
      <span v-if="data.routes">
        <b>Desde:</b> {{ data.routes[0].legs[0].origen.direccion }}<br />
        <b>Hasta:</b> {{ data.routes[0].legs[0].destino.direccion }}<br />
        <span v-if="data.routes[0].legs.length > 1">
          (con {{ data.routes[0].legs.length - 1 }} puntos de detención en la
          ruta)<br />
        </span>
      </span>
    </span>
    <v-expansion-panels v-if="data">
      <v-expansion-panel v-for="ruta in data.routes" :key="ruta">
        <v-expansion-panel-title v-slot="{ expanded }" class="text-caption">
          <v-row no-gutters>
            <v-col class="d-flex justify-start" cols="9">
              <b>por {{ ruta.route }}</b>
            </v-col>
            <v-col class="text--secondary" cols="12">
              <v-fade-transition leave-absolute>
                <span v-if="expanded">Detalle de viaje </span>
                <v-row v-else style="width: 100%" no-gutters>
                  <v-col class="d-flex justify-start" cols="6">
                    {{
                      ruta.kilometros.toString().replace(".", ",") ||
                      "No existe"
                    }}
                    kms
                  </v-col>
                  <v-col class="d-flex justify-end" cols="6">
                    {{ ruta.tiempo_trafico || "No existe" }} mins
                    <span v-if="ruta.tiempo_demora > 0"
                      >({{ ruta.tiempo_demora || "No existe" }} mins en
                      tráfico)</span
                    >
                  </v-col>
                  <v-col class="d-flex justify-end text-bond" cols="12">
                    <b>{{ formatoMonedaChile(ruta.total) || "No existe" }}</b>
                  </v-col>
                </v-row>
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <!--
            Esto solo se usa en caso que sea mobile
            -->
            <v-col cols="12" v-if="isMobile">
              <v-form @submit.prevent="usarRuta(ruta)">
                <buttonSubmitform
                  labelSet="Usar esta ruta"
                  preIcon="mdi-map-marker-path"
                  :validating="validation"
                  append-icon="mdi-chevron-right"
                />
              </v-form>
            </v-col>
            <v-col :cols="isMobile ? 12 : 6" align="center" justify="center">
              <buttonCancelform
                labelSet="Ver ruta"
                preIcon="mdi-map-marker-path"
                :validating="validation"
                @accion="abrirVerMapaCarrera(ruta)"
              />
            </v-col>
            <v-col md="6" v-if="!isMobile">
              <v-form @submit.prevent="usarRuta(ruta)">
                <buttonSubmitform
                  labelSet="Usar esta ruta"
                  preIcon="mdi-map-marker-path"
                  :validating="validation"
                  append-icon="mdi-chevron-right"
                />
              </v-form>
            </v-col>
            <v-col class="d-flex justify-start" cols="6">
              {{ ruta.kilometros.toString().replace(".", ",") || "No existe" }}
              kms
            </v-col>
            <v-col class="d-flex justify-end" cols="6">
              {{ ruta.tiempo_trafico || "No existe" }} mins
              {{
                ruta.tiempo_demora
                  ? " (" + ruta.tiempo_demora + " en tráfico)"
                  : ""
              }}
            </v-col>
            <v-col class="d-flex justify-end text-bond" cols="12">
              <b>{{ formatoMonedaChile(ruta.total) || "No existe" }}</b>
            </v-col>
            <v-col cols="12" v-for="legs in ruta.legs" :key="legs">
              <span>
                <v-card>
                  <v-card-text>
                    <div class="text-caption font-weight-bold">
                      De {{ legs.origen.direccion }} <br />a
                      {{ legs.destino.direccion }} <br />
                      {{ legs.kilometros }} kms - {{ legs.tiempo_trafico }} mins
                    </div>
                    <v-divider class="border-opacity-100" />
                    <v-rows
                      v-for="steps in legs.pasos"
                      :key="steps"
                      class="text-caption"
                    >
                      <v-col cols="12">
                        - <span v-html="steps.instrucciones" />
                      </v-col>
                    </v-rows>
                  </v-card-text>
                </v-card>
              </span>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>

  <ver_mapa_carrera ref="ver_mapa_carrera" @cerrarVerMapa="cerrarVerMapa" @usarRuta="usarRuta"/>
  <mensajeUsuario ref="mensajeUsuario" />
</template>

<script>
import {
  paletColor,
  formatoMonedaChile,
  enviarJsonPOST,
  isMobile,
} from "@/helpers/helpers";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import ver_mapa_carrera from "@/components/carrera/ver_mapa_carrera.vue";
import buttonCancelform from "@/components/form/buttonCancelform.vue";
import buttonSubmitform from "@/components/form/buttonSubmitform.vue";
export default {
  name: "alternarivaRutas",

  data: () => ({
    paletColor: paletColor,
    validation: false,
    formatoMonedaChile: formatoMonedaChile,
    isMobile: isMobile(),
  }),
  props: {
    data: Object,
  },
  components: {
    mensajeUsuario,
    ver_mapa_carrera,
    buttonCancelform,
    buttonSubmitform,
  },
  methods: {
    abrirVerMapaCarrera: function (ruta) {
      this.$refs.ver_mapa_carrera.processRouteData2(
        ruta.overview_polyline,
        ruta.legs,
        ruta.kilometros,
        null,
        ruta,
      );
    },
    usarRuta: function (ruta) {
      this.$emit("usarRuta", ruta);
    },
  },
};
</script>

<style>
</style>