<template>
  <v-dialog
    persistent
    width="100%"
    height="100%"
    max-width="100%"
    max-height="100vh"
    v-if="localdialogverutamapa"
    v-model="localdialogverutamapa"
  >
    <v-container :class="isMobile ? 'mx-0 px-0 my-1 py-1' : ''">
      <v-card
        :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
        elevation-6
        max-width="100%"
      >
        <v-card-title
          style="height: 50px"
          :style="'color: ' + paletColor.buttons.cancel.text + ';'"
        >
          <v-row>
            <v-col>Recorriendo Ruta asignada</v-col>
            <v-col align="right" cols="1"
              ><v-btn
                :style="
                  'background-color: ' + paletColor.card.backgroundgral + ';'
                "
                density="compact"
                icon="mdi-close-circle-outline"
                @click="volverMenu"
              ></v-btn
            ></v-col>
          </v-row>
        </v-card-title>
        <v-card-text
          :style="'background-color: ' + paletColor.colorNeutro + ';'"
        >
          <!--recorriendoRuta v-if="polyline"
        :polyline="polyline"
      /-->
          <recorriendoRuta
            v-if="polyline"
            :polyline="polyline"
            :recorrido="recorrido"
            :rutas="rutas"
                @updatePolylineReal="updatePolylineReal"
          />
        </v-card-text>
        <v-card-actions>
            <btn_flujo
                v-if="parseInt(carrera.carrera_estado_id) == 1000"
                textoIzquierda="Incidente"
                textoDerecha="Completar viaje"
                @derecha="derecha"
                @izquierda="izquierda"
            />
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { paletColor } from "@/helpers/helpers";
import recorriendoRuta from "@/components/mapa/muestra_ruta_online_vehiculo.vue";

import btn_flujo from "@/components/form/buttonDeslizar.vue";

export default {
  data: () => ({
    paletColor: paletColor,
    localdialogverutamapa: false,
    polyline: null,
    recorrido: null,
    carrera: null,
  }),
  components: {
    recorriendoRuta,
    btn_flujo,
  },
  watch: {},
  props: {
    rutas: {
      type: Array,
      required: true,
    },
  },
  mounted: function () {
    console.log("carrera",this.carrera);
  },
  methods: {
    updatePolylineReal: function(poly){
        this.$emit("updatePolylineReal",poly);
    },
    derecha: function(){
        this.$emit("enDestino");
    },
    izquierda: function(){
        this.$emit("eliminar");
    },
    volverMenu: function () {
      this.localdialogverutamapa = false;
    },
  },
};
</script>

<style>
</style>