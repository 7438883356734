<template>
  <v-dialog
    persistent
    width="90%"
    max-width="90%"
    max-height="90vh"
    v-if="localdialogverutamapa"
    v-model="localdialogverutamapa"
  >
    <v-card
      elevation-6
      :style="`
        background-color: ${paletColor.card.backgroundgral};
        color: ${paletColor.colorNeutro};
        max-height: 90vh;
        overflow-y: auto;
      `"
    >
      <v-card-title> 
        <v-row>
        <v-col>Ruta</v-col>
        <v-col align="right"><v-btn :style="'background-color: ' + paletColor.card.backgroundgral + ';'" density="compact"  icon="mdi-close-circle-outline" @click="cerrar"></v-btn></v-col>
      </v-row>
         </v-card-title>
      <v-card-text
        :style="`
          background-color: ${paletColor.colorFondoAlternativo};
          max-height: calc(90vh - 100px);
          overflow-y: auto;
          position: relative;
        `"
      >
        <div
          id="map-container"
          :style="`
            width: 100%;
            height: 400px; /* Ajusta esta altura según el diseño */
            max-height: 100%;
          `"
        >
          <mapa_ruta
            v-id="textPolyline"
            :encodedPolyline="textPolyline"
            :locations="legs_local"
          />
        </div>
      </v-card-text>
      <v-card-action>
        <v-container>
          <v-row dense>
            <!--
            Esto solo se usa en caso que sea mobile
            -->
            <v-col  :cols="isMobile ? 12 : 6" align="center" justify="center" v-if="isMobile && ruta" :v-show="isMobile && ruta">
              <v-form @submit.prevent="usarRuta(ruta)">
                <buttonSubmitform
                  labelSet="Usar esta ruta"
                  preIcon="mdi-map-marker-path"
                  :validating="validation"
                  append-icon="mdi-chevron-right"
                />
              </v-form>
            </v-col>
            <v-col  :cols="isMobile ? 12 : 6" align="center" justify="center"
              ><buttonCancelform
                labelSet="Iniciar Ruta Google"
                @cerrar="navigateToGoogleMaps"
                preIcon="mdi-google-maps"
            /></v-col>

            <v-col  :cols="isMobile ? 12 : 6" align="center" justify="center" v-if="isMobile" :v-show="isMobile"
              ><buttonCancelform
                labelSet="Iniciar Ruta Waze"
                @cerrar="navigateToWaze"
                preIcon="mdi-waze"
            /></v-col>
          </v-row>
            <!--
            Esto solo se usa en caso que sea NO mobile
            -->
            <v-col  :cols="isMobile ? 12 : 6" align="center" justify="center" v-if="!isMobile && ruta" :v-show="!isMobile && ruta">
              <v-form @submit.prevent="usarRuta(ruta)">
                <buttonSubmitform
                  labelSet="Usar esta ruta"
                  preIcon="mdi-map-marker-path"
                  :validating="validation"
                  append-icon="mdi-chevron-right"
                />
              </v-form>
            </v-col>
        </v-container>
      </v-card-action>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  enviarJsonPOST,
  formatoMonedaChile,
  paletColor,
  isMobile,
} from "@/helpers/helpers.js";
import { createLogger } from "vuex";
import buttonCancelform from "../form/buttonCancelform.vue";
import buttonSubmitform from "@/components/form/buttonSubmitform.vue";
import mapa_ruta from "@/components/mapa/muestra_ruta.vue";

export default {
  data: () => ({
    zoom: 8,
    textPolyline: null,
    locations: [],
    polylineOptions: {
      strokeColor: "#325A82", // Color de la línea (rojo)
      strokeOpacity: 1.0, // Opacidad de la línea
      strokeWeight: 3, // Grosor de la línea
    },
    localdialogverutamapa: false,
    isInfoWindowOpen: false,
    selectedMarker: null,
    paletColor: paletColor,
    center: { lat: -33.45, lng: -70.65 }, // Centro inicial del mapa
    routePath: [], // Contendrá los puntos de la ruta a dibujar
    markers: [],
    origenlocal: null,
    destinolocal: null,
    legs_local: null,
    overview_polyline_points: null,
    anchoBoton: 6,
    isMobile: isMobile(),
    distancia: 40,
    peajes: [],
    ruta: null,
  }),
  props: {},
  components: {
    buttonCancelform,
    buttonSubmitform,
    mapa_ruta,
  },
  watch: {},

  mounted: async function () {
    const usuario = JSON.parse(sessionStorage.getItem("usuarioDriverIn"));
    if (this.isMobile) {
      this.anchoBoton = 4;
    } else {
      this.anchoBoton = 6;
    }
  },
  methods: {
    cerrar: function () {
      this.localdialogverutamapa = false;
    },
    onInfoWindowClose: function () {
      this.isInfoWindowOpen = false;
    },
    openInfoWindow: function (marker) {
      this.isInfoWindowOpen = true;
      this.selectedMarker = marker;
    },
    mostrarMapa: function () {
      this.localdialogverutamapa = true;
      this.initializeMap();
    },
    processRouteData2: function () {
      this.isInfoWindowOpen = null;
      this.selectedMarker = null;
      this.routePath = null;
      this.markers = [];
      let overviewPolyline = null;
      if (arguments.length > 0) {
        overviewPolyline = arguments[0];
        this.textPolyline = overviewPolyline;
      }
      if (arguments.length > 1) {
        this.legs_local = arguments[1];
      }
      if (arguments.length > 2) {
        this.distancia = arguments[2];
      }
      if (arguments.length > 3) {
        this.peajes = arguments[3];
      }
      
      if (arguments.length > 4) {
        this.ruta = arguments[4];
      }
      if (overviewPolyline) {
        if (typeof this.legs_local == "object") {
          if (this.legs_local && Array.isArray(this.legs_local)) {
            for (let x = 0; x < this.legs_local.length; x++) {
              if (x === 0) {
                this.markers.push({
                  position: this.legs_local[x].origen,
                  title: "Inicio",
                  icon: "img/iconos/IconoMapaLocationsInicio.gif",
                  description: this.legs_local[x].origen.direccion,
                });
              }
              this.markers.push({
                position: this.legs_local[x].destino,
                title: "Término",
                icon: "img/iconos/IconoMapaLocationsFinal.gif",
                description: this.legs_local[x].destino.direccion,
              });
            }
          }
        }
      }
      this.localdialogverutamapa = true;
    },
    navigateToGoogleMaps() {
      if (
        confirm(
          "Al abrir Google, puede que entregue una ruta distinta a la calculada, ¿esta conforme con eso y se hace responsable por las diferencias?"
        )
      ) {
      if (this.markers.length > 0) {
        const origin = `${this.markers[0].position.lat},${this.markers[0].position.lng}`; // Primer punto de la ruta
        const destination = `${
          this.markers[this.markers.length - 1].position.lat
        },${this.markers[this.markers.length - 1].position.lng}`; // Último punto de la ruta

        let waypoints = "";
        if (this.markers) {
          for (let m = 0; m < this.markers.length; m++) {
            if (m != 0 && m < this.markers.length - 1) {
              if (waypoints == "") {
                waypoints = `${this.markers[m].position.lat},${this.markers[m].position.lng}`;
              } else {
                waypoints +=
                  "|" +
                  `${this.markers[m].position.lat},${this.markers[m].position.lng}`;
              }
            }
          }
        }

        // Construir URL de Google Maps con origin, destination y waypoints
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}&waypoints=${waypoints}&travelmode=driving`;

        // Redirigir a Google Maps
        window.open(googleMapsUrl, "_blank");
      }
    }
    },

    navigateToWaze() {
      if (
        confirm(
          "Al abrir Google, puede que entregue una ruta distinta a la calculada, ¿esta conforme con eso y se hace responsable por las diferencias?"
        )
      ) {
        if (this.markers.length > 0) {
          const destination = `${
            this.markers[this.markers.length - 1].position.lat
          },${this.markers[this.markers.length - 1].position.lng}`; // Último punto de la ruta

          // Construir URL de Waze con la latitud y longitud del destino
          const wazeUrl = `waze://?ll=${destination}&navigate=yes`;

          // Redirigir a Waze
          window.open(wazeUrl, "_blank");
        }
      }
    },
    usarRuta: function(){
      this.$emit("usarRuta",this.ruta);
    },
  },
};
</script>

<style>
@media (max-width: 600px) {
  .v-dialog {
    width: 100% !important;
    max-height: 100vh !important;
  }
}
</style>
