<template>
  <v-card
    class="mx-2 my-2"
    elevation-6
    :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
    max-width="90%"
  >
    <v-card-title
      style="height: 50px"
      :style="'color: ' + paletColor.buttons.cancel.text + ';'"
    >
      <v-row>
        <v-col>Lista de servicios</v-col>
        <v-col align="right"
          ><v-btn
            :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
            density="compact"
            icon="mdi-close-circle-outline"
            @click="volverMenu"
          ></v-btn
        ></v-col>
      </v-row>
    </v-card-title>
    <v-card-text :style="'background-color: ' + paletColor.colorNeutro + ';'">
      <v-row dense v-if="servicios">
        <v-col cols="12" md="3" v-for="(val, index) in servicios" :key="index">
          <carServicio :servicio="val" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  
  <v-bottom-navigation>
          <v-btn @click="nuevoServicio">
            <v-icon>mdi-cash-plus</v-icon>
            <span style="font-size: 10px">Servicio</span>
          </v-btn>
    </v-bottom-navigation>
  <mensajeUsuario ref="mensajeUsuario" />
  <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="salirApp" />
</template>

<script>
import { paletColor, obtenerJsonGET, obtenerLogoApp } from "@/helpers/helpers";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import carServicio from "@/components/servicio/vistaCardListaServicio.vue";
export default {
  name: "lista_servicio",
  data: () => ({
    servicios: null,
    paletColor: paletColor,
  }),
  components: {
    mensajeUsuario,
    carServicio,
  },
  mounted: function () {
    this.obtenerServicios();
  },
  methods: {
    volverMenu: function(){
        this.$router.replace("/start");
    },
    nuevoServicio: function(){
      this.$router.push("/cambiarCondiguracionServicio/0/");
    },
    obtenerServicios: async function () {
      let data = await obtenerJsonGET(
        "/servicio/obtener_listado_servicio_conductor/",
        null
      );
      if (data.error == "0") {
        this.servicios = data.data;
      } else {
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      }
    },
  },
};
</script>

<style>
</style>