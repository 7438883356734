<template>
  <v-container :class="isMobile ? 'px-0 py-0' : ''">
    <v-form
      ref="form_ingreso"
      @submit.prevent="guardarCarrera"
      v-model="validForm"
    >
      <div class="text-h6">Costos de Viaje</div>
      <v-row dense class="text-caption" v-if="rutaLocal">
        <v-col cols="12" md="6">
          <b>Origen:</b> {{ rutaLocal.legs[0].origen.direccion }}
        </v-col>
        <v-col cols="12" md="6">
          <b>Destino:</b>
          {{ rutaLocal.legs[rutaLocal.legs.length - 1].destino.direccion }}
          <b>{{
            rutaLocal.legs.length > 1
              ? "con " +
                (rutaLocal.legs.length - 1) +
                " destino" +
                (rutaLocal.legs.length > 2 ? "s" : "") +
                " intermedio" +
                (rutaLocal.legs.length > 2 ? "s" : "")
              : ""
          }}</b>
        </v-col>
        <v-col cols="6"> {{ rutaLocal.kilometros }} Kms. </v-col>
        <v-col cols="6"> {{ rutaLocal.tiempo_trafico }} Mins. </v-col>
        <v-col cols="6"> {{ rutaLocal.tiempo_demora }} Mins en tráfico. </v-col>
        <v-col cols="6">
          <b>Total Estimado: {{ formatoMonedaChile(rutaLocal.total) }}.-</b>
        </v-col>
      </v-row>
      <v-row dense class="text-caption" v-if="rutaLocal">
        <v-col cols="12" md="6" lg="4" v-if="carrera_cod">
          <txt_numero
            ref="numeroVale"
            labelSet="Número de vale"
            preIconoSet="mdi-text-box-check-outline"
            :valorSet="nroVale"
            :requeridoSet="cerrarCarrera"
            :valorMinimoSet="0"
            :valorMaximoSet="999999999"
            :readonlySet="!edit"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6" lg="4" v-if="carrera_cod">
          <file_archivo
            ref="archivos"
            labelSet="Foto Vale"
            :max-files-set="2"
            :multiple-set="true"
            acceptSet="image/*"
            :valorSet="imagenVale"
            :requeridoSet="cerrarCarrera"
            :readonlySet="!edit"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <txt_fecha
            ref="fecha_hora"
            labelSet="Fecha/hora del servicio"
            textoAlternativoSet="Seleccione la fecha y hora del servicio."
            :requeridoSet="true"
            :valorSet="fecha_hora"
            :readonlySet="!edit"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <txt_checkbox
            ref="efectivo"
            :valor-set="efectivo"
            labelSet="¿Es efectivo?"
            :inlineSet="true"
            :opciones="esefectivoitem"
            :valorSet="esefectivo"
            :readonlySet="!edit"
          />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <txt_checkbox
            ref="tarifafija"
            :valor-set="tarifa_fija"
            labelSet="¿Es tarifa fija?"
            :inlineSet="true"
            :opciones="estarifafijaitem"
            :valorSet="estarifafija"
            :readonlySet="!edit"
          />
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <txt_numero
            ref="cantidadTag"
            labelSet="Cantidad de TAG"
            preIconoSet="mdi-boom-gate-up-outline"
            :valorSet="cantidadTag"
            :requeridoSet="true"
            :valorMinimoSet="0"
            :valorMaximoSet="30"
            @SetValorCambia="recalculaCarrera"
            :readonlySet="!edit"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <txt_numero
            ref="minutoEspera"
            labelSet="Minutos de espera"
            preIconoSet="mdi-clock-alert-outline"
            :valorSet="minutoEspera"
            :requeridoSet="true"
            :valorMinimoSet="0"
            :valorMaximoSet="120"
            @SetValorCambia="recalculaCarrera"
            :readonlySet="!edit"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <txt_numero
            ref="valorCarrera"
            labelSet="Valor total Carrera"
            preIconoSet="mdi-currency-usd"
            :valorSet="valor_conductor"
            :requeridoSet="true"
            :valorMinimoSet="rutaLocal.bajada_bandera"
            :valorMaximoSet="500000"
            :readonlySet="!edit"
            :validatingSet="validando"
          />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <txt_textoGrande
            ref="observaciones"
            labelSet="Observacion"
            :valorSet="observacion"
            :requeridoSet="exigeObservacion"
            :maxLengthSet="1000"
            :readonlySet="!edit"
            :validatingSet="validando"
          />
        </v-col>
      </v-row>
      <v-row dense class="text-caption pt-3">
        <v-col cols="12" v-if="rutaLocal && isMobile">
          <buttonSubmitform
            labelSet="Guardar Viaje"
            @accion="volverAlternativasCarrera"
            :readonlySet="!edit"
            :loading="validando"
            append-icon="mdi-content-save-move"
          />
        </v-col>
        <v-col :cols="isMobile ? 12 : 6">
          <buttonCancelform
            labelSet="Ver Pasajeros"
            @accion="volverRegistroPasajetros"
            :loading="validando"
            preIcon="mdi-chevron-left"
          />
        </v-col>
        <v-col cols="6" v-if="rutaLocal && !isMobile">
          <buttonSubmitform
            labelSet="Guardar Viaje"
            @accion="volverAlternativasCarrera"
            :readonlySet="!edit"
            :loading="validando"
            append-icon="mdi-content-save-move"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
  <mensajeUsuario ref="mensajeUsuario" />
  <mensajeUsuario ref="mensajeUsuarioOk" @cerrar="TerminoRutaExitoso" />
  <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="salirApp" />
</template>

<script>
import {
  formatoMonedaChile,
  enviarJsonPOST,
  obtenerJsonGET,
  isMobile,
} from "@/helpers/helpers";
import file_archivo from "@/components/form/archivos.vue";
import cbo_formulario from "@/components/form/combobox.vue";
import txt_fecha from "@/components/form/fechaHora.vue";
import txt_checkbox from "@/components/form/checkBox.vue";
import buttonCancelform from "@/components/form/buttonCancelform.vue";
import buttonSubmitform from "@/components/form/buttonSubmitform.vue";
import txt_numero from "@/components/form/numero.vue";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import txt_textoGrande from "@/components/form/textoGrande.vue";

export default {
  name: "registraDatosCarrera",

  data: () => ({
    usuario: JSON.parse(sessionStorage.getItem("usuarioDriverIn")),
    rutaLocal: null,
    pasajerosLocal: null,
    fecha_hora: null,
    nroVale: "",
    imagenVale: null,
    esefectivo: "0",
    esefectivoitem: [
      { valor: "0", label: "No" },
      { valor: "1", label: "Si" },
    ],
    estarifafija: "0",
    estarifafijaitem: [
      { valor: "0", label: "No" },
      { valor: "1", label: "Si" },
    ],
    cantidadTag: "0",
    totalTag: 0,
    minutoEspera: "0",
    totalEspera: "0",
    total: 0,
    valor_conductor: 0,
    observacion: "",
    edit: true,
    cantidadImagenes: 0,
    exigeObservacion: false,
    validando: false,
    formatoMonedaChile: formatoMonedaChile,
    isMobile: isMobile(),
    entorno: process.env.NODE_ENV,
    isRequireImage: false,
  }),
  props: {
    carrera_cod: String,
    ruta: Object,
    data: Object,
    pasajeros: Object,
    empresa_cod: String,
    servicio_cod: String,
    convenio_cod: String,
    requiereVale: Boolean,
    cerrarCarrera: Boolean,
  },
  components: {
    cbo_formulario,
    txt_fecha,
    txt_checkbox,
    buttonSubmitform,
    buttonCancelform,
    txt_numero,
    file_archivo,
    mensajeUsuario,
    txt_textoGrande,
  },
  watch: {
    ruta: function (val) {
      this.rutaLocal = this.val;
      if (val) {
        if (val.departureTime) {
          this.fecha_hora = new Date(val.departureTime);
        }
      }
    },
    pasajeros: function (val) {
      this.pasajerosLocal = this.val;
      this.obtenerCombobox();
    },
  },
  mounted: function () {
    if (this.ruta) {
      this.rutaLocal = this.ruta;

      if (this.ruta.departureTime) {
        this.fecha_hora = new Date(this.ruta.departureTime);
      }

      if (this.ruta.total) {
        this.total = this.ruta.total;
      }
      if (this.ruta.valor_conductor) {
        this.valor_conductor = this.ruta.valor_conductor;
      }
      if (this.ruta.tag) {
        this.cantidadTag = this.ruta.tag;
      }
      if (this.ruta.minuto_espera) {
        this.minutoEspera = this.ruta.minuto_espera;
      }
      if (this.ruta.observacion) {
        this.observacion = this.ruta.observacion;
      }
      if (this.ruta.comprobante) {
        this.nroVale = this.ruta.comprobante;
      }

      if (this.ruta.minuto_espera_precio) {
        this.totalEspera = this.ruta.minuto_espera_precio;
      }
      if (this.ruta.tag_precio) {
        this.totalTag = this.ruta.tag_precio;
      }
      if (this.ruta.efectivo) {
        this.esefectivo = this.ruta.efectivo + "";
      }
      if (this.ruta.tarifa_fija) {
        this.estarifafija = this.ruta.tarifa_fija + "";
      }
    }
    if (this.pasajeros) {
      this.pasajerosLocal = this.pasajeros;
    }
  },
  methods: {
    guardarCarrera: async function () {
      this.validando = true;
      if (parseFloat(this.ruta.cantidad_imagenes) <= 0 && this.carrera_cod) {
        this.isRequireImage = true;
      }
      let { valid } = await this.$refs.form_ingreso.validate();
      if (valid) {
        if (
          parseFloat(this.ruta.total) ==
            parseFloat(this.$refs.valorCarrera.valor) ||
          this.$refs.observaciones.valor
        ) {
          let fd = new FormData();
          fd.append("empresa_cod", this.empresa_cod);
          fd.append("Servicio", this.servicio_cod);
          fd.append("convenio", this.convenio_cod);
          fd.append("fecha_hora_servicio", this.$refs.fecha_hora.valor);
          fd.append("efectivo", this.$refs.efectivo.valor);
          fd.append("tarifafija", this.$refs.tarifafija.valor);
          fd.append("cantidadTag", this.$refs.cantidadTag.valor);
          fd.append("minutoEspera", this.$refs.minutoEspera.valor);
          fd.append("valorCarrera", this.$refs.valorCarrera.valor);
          fd.append("observaciones", this.$refs.observaciones.valor);
          fd.append("pasajeros", JSON.stringify(this.pasajeros));
          fd.append("totalTag", this.totalTag);
          fd.append("totalEspera", this.totalEspera);
          fd.append("cerrarCarrera", this.cerrarCarrera ? 1 : 0);

          if (this.carrera_cod) {
            fd.append("carrera_cod", this.carrera_cod);
            console.log(
              "this.$refs.numeroVale.valor",
              this.$refs.numeroVale.valor
            );
            fd.append(
              "comprobante",
              this.$refs.numeroVale.valor ? this.$refs.numeroVale.valor : ""
            );

            if (this.$refs.archivos.valor) {
              for (var x = 0; x < this.$refs.archivos.valor.length; x++) {
                if (this.entorno.toLowerCase() != "development") {
                  let size = this.$refs.archivos.valor[x].size / 1024 / 1024;
                  let date = this.$refs.archivos.valor[x].lastModifiedDate;

                  const timeDifference = Math.abs(new Date() - date);

                  // 15 minutos en milisegundos (15 minutos * 60 segundos * 1000 milisegundos)
                  const fifteenMinutesInMs = 30 * 60 * 1000;

                  if (timeDifference > fifteenMinutesInMs) {
                    this.$refs.mensajeUsuario.mostrar(
                      "La imagen es demasiado antigua, por favor tome una nueva imagen.",
                      "Advertencia"
                    );
                    this.validando = false;
                    return this.validando;
                  } else if (size > 5) {
                    this.$refs.mensajeUsuario.mostrar(
                      "Los archivos adjuntos no pueden superar 5MB, por favor corregir.",
                      "Advertencia"
                    );
                    this.validando = false;
                    return this.validando;
                  }
                  fd.append("fotos[]", this.$refs.archivos.valor[x]);
                }
              }
            }
          }

          fd.append("ruta", JSON.stringify(this.ruta));
          fd.append("data", JSON.stringify(this.data));

          let data = await enviarJsonPOST(
            "/carrera/crearcarreraconductor/",
            fd
          );
          if (data.error == 0) {
            this.$refs.mensajeUsuarioOk.mostrar(data.message, "Información");
          } else {
            if (data.error == 1000) {
              this.$refs.mensajeUsuarioSalir.mostrar(data.message);
            } else {
              this.$refs.mensajeUsuario.mostrar(data.message);
            }
          }
        } else {
          this.exigeObservacion = true;
          this.$refs.mensajeUsuario.mostrar(
            "Es necesario que justifique en observaciones la diferencia de precio sobre lo estimado por el sistema.",
            "Advertencia"
          );
          const { valid } = await this.$refs.form_ingreso.validate();
        }
      } else {
        this.$refs.mensajeUsuario.mostrar(
          "Debe ingresar los datos requeridos",
          "Advertencia"
        );
      }
      this.validando = false;
    },
    TerminoRutaExitoso: function () {
      this.$router.replace("/start");
    },

    volverRegistroPasajetros: function () {
      this.$emit("volverRegistroPasajetros");
    },
    recalculaCarrera: async function () {
      this.validando = true;
      this.cantidadTag = this.$refs.cantidadTag.valor;
      this.minutoEspera = this.$refs.minutoEspera.valor;

      this.totalTag =
        parseFloat(this.cantidadTag) * parseFloat(this.ruta.valorportag);

      if (
        parseFloat(this.ruta.tiempoEsperaPermitido) <
        parseFloat(this.minutoEspera)
      ) {
        this.totalEspera =
          (parseFloat(this.minutoEspera) -
            parseFloat(this.ruta.tiempoEsperaPermitido)) *
          parseFloat(this.ruta.valorporminutoespera);
      } else {
        this.totalEspera = 0;
      }

      let total =
        parseFloat(this.ruta.bajada_bandera) +
        parseFloat(this.ruta.kilometros_precio) +
        parseFloat(this.ruta.tiempo_demora_precio) +
        this.totalTag +
        this.totalEspera;

      this.total = Math.round(total / 10) * 10;
      this.ruta.total = this.total;
      this.valor_conductor = this.total;

      this.validando = false;
    },
    salirApp: function () {
      this.$router.replace("/");
    },
  },
};
</script>

<style>
</style>