<template>
  <v-dialog
    persistent
    width="auto"
    height="auto"
    max-width="95%"
    v-if="dialogEvalListaGasto"
    v-model="dialogEvalListaGasto"
  >
    <v-card
      :class="'mx-auto'"
      :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
      elevation-6
      width="95%"
      max-width="95%"
    >
      <v-card-title
        style="height: 50px"
        :style="'color: ' + paletColor.buttons.cancel.text + ';position: sticky;top: 0; z-index: 2;background-color: '+paletColor.card.backgroundgral+';'
        +'paletColor.card.backgroundgral;padding: 16px;font-size: 18px;font-weight: bold;'"
      >
        <v-row>
          <v-col>{{ title }}</v-col>
          <v-col align="right" cols="1"
            ><v-btn
              :style="
                'background-color: ' + paletColor.card.backgroundgral + ';'
              "
              density="compact"
              icon="mdi-close-circle-outline"
              @click="cerrar"
            ></v-btn
          ></v-col>
        </v-row>
      </v-card-title>

      <v-card-text :style="'background-color: ' + paletColor.colorNeutro + ';'">
        <v-row dense>
          <v-col
            cols="12"
            md="4"
            v-for="(carrera, index) in items"
            :key="index"
          >
            <v-card
              :class="'mx-auto'"
              :style="
                'background-color: ' + paletColor.card.backgroundgral + ';'
              "
              elevation-6
            >
              <v-card-title
                :style="'color: ' + paletColor.buttons.cancel.text + ';'"
              >
                {{ formatoMonedaChile(carrera.valor) }}<br />
              </v-card-title>
              <v-card-subtitle
                :style="'background-color: ' + paletColor.colorNeutro + ';'"
              >
                Comrpobante: {{ carrera.comprobante }}
              </v-card-subtitle>
              <v-card-text
                class="text-caption"
                :style="'background-color: ' + paletColor.colorNeutro + ';'"
              >
                <v-row dense>
                  <v-col cols="12">
                    {{ carrera.descripcion }}
                  </v-col>
                  <v-col cols="6">Fecha de carga:</v-col>
                  <v-col cols="6" class="text-right">{{
                    carrera.fecha_carga
                  }}</v-col>
                  <v-col cols="6">Kilometraje</v-col>
                  <v-col cols="6" class="text-right">{{
                    formatearNumero(carrera.kilometraje)
                  }}</v-col>
                  <v-col cols="6">Recorrido</v-col>
                  <v-col cols="6" class="text-right">{{
                    formatearNumero(carrera.recorrido)
                  }}</v-col>
                  <v-col cols="6">Valor x litro</v-col>
                  <v-col cols="6" class="text-right">{{
                    formatoMonedaChile(carrera.valorxlitro)
                  }}</v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>

  <mensajeUsuario ref="mensajeUsuario" />
  <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="cerrar" />
</template>
  
  <script>
import {
  obtenerJsonGET,
  paletColor,
  isMobile,
  formatoMonedaChile,formatearNumero,
} from "@/helpers/helpers";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
export default {
  data: () => ({
    title: "Listado de combustibles",
    isMobile: isMobile(),
    formatoMonedaChile: formatoMonedaChile,
    formatearNumero: formatearNumero,
    dialogEvalListaGasto: false,
    paletColor: paletColor,
    items: [],
    periodo: null,
    fec_ini: null,
    fec_fin: null,
  }),
  components: {
    mensajeUsuario,
  },
  methods: {
    cargarGastos: async function () {
      let param = {};
      if (this.periodo) {
        param.periodo = this.periodo;
      }
      if (this.fec_ini) {
        param.fecini = this.fec_ini;
      }
      if (this.fec_fin) {
        param.fecfin = this.fec_fin;
      }
      let data = await obtenerJsonGET("/combustible/obtener_listado/", param);
      if (data.error == 0) {
        this.items = data.data;
        if (this.items.length <= 0) {
          this.$refs.mensajeUsuarioSalir.mostrar(
            "No hay combustible en el período seleccionado"
          );
        }
      } else {
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      }
    },
    cerrar: function () {
      this.dialogEvalListaGasto = false;
    },
    SalirSistema: function () {
      this.$router.replace("/");
    },
  },
};
</script>
  
<style>
</style>