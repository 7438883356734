<template>
  <div id="map" style="height: 450px; width: 100%"></div>
</template>
  
  <script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import polyline from "@mapbox/polyline";

export default {
  name: "RouteMap",
  props: {
    encodedPolyline: {
      type: String,
      required: true,
    },
    locations: {
      // Arreglo con origen y destinos
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      map: null,
      routeLayer: null,
      markers: [], // Guardará todos los marcadores para fácil manejo
    };
  },
  methods: {
    initializeMap() {
      if (this.map) {
        console.warn("El mapa ya está inicializado, destruyéndolo...");
        this.map.remove(); // Elimina el mapa existente si ya fue creado
      }

      const mapContainer = document.getElementById("map");
      if (!mapContainer) {
        console.error("No se encontró el contenedor del mapa.");
        return;
      }

      // Inicializa el mapa
      this.map = L.map("map").setView([0, 0], 2);

      // Agregar capa base
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution: '&copy; <a href="https://katal.cl">Katal</a>',
      }).addTo(this.map);

      // Dibujar la ruta y agregar marcadores
      if (this.encodedPolyline) {
        this.drawRoute(this.encodedPolyline);
      }
      if (this.locations.length > 0) {
        this.addMarkers(this.locations);
      }
    },
    drawRoute(encodedPolyline) {
      if (!this.map) {
        console.warn("El mapa no está inicializado.");
        return;
      }

      const coordinates = polyline.decode(encodedPolyline);

      // Eliminar capa de ruta anterior si existe
      if (this.routeLayer) {
        this.map.removeLayer(this.routeLayer);
      }

      // Dibujar la nueva ruta
      this.routeLayer = L.polyline(coordinates, {
        color: "blue",
        weight: 4,
        opacity: 0.7,
      }).addTo(this.map);

      // Centrar el mapa según los límites de la ruta
      const bounds = this.routeLayer.getBounds();
      this.map.fitBounds(bounds, { padding: [20, 20] });
    },
    addMarkers(locations) {
      if (!this.map) {
        console.warn("El mapa no está inicializado.");
        return;
      }

      const origenIcon = L.icon({
        iconUrl: "/img/iconos/IconoMapaLocationsInicio.gif",
        iconSize: [25, 40],
        iconAnchor: [12.5, 40],
      });

      const destinoIcon = L.icon({
        iconUrl: "/img/iconos/IconoMapaLocationsFinal.gif",
        iconSize: [25, 40],
        iconAnchor: [12.5, 40],
      });

      // Limpiar marcadores previos si existen
      this.markers.forEach((marker) => this.map.removeLayer(marker));
      this.markers = [];

      locations.forEach((location, index) => {
        // Agregar marcador para el origen
        if(index == 0){
        const origenMarker = L.marker(
          [location.origen.lat, location.origen.lng],
          { icon: origenIcon }
        )
          .addTo(this.map)
          .bindPopup(location.origen.direccion)
          .on("click", () => {
            console.info(`Origen: ${location.origen.direccion}`);
          });
        this.markers.push(origenMarker);}

        // Agregar marcador para el destino
        const destinoMarker = L.marker(
          [location.destino.lat, location.destino.lng],
          { icon: destinoIcon }
        )
          .addTo(this.map)
          .bindPopup(location.destino.direccion)
          .on("click", () => {
            console.info(`Destino: ${location.destino.direccion}`);
          });
        this.markers.push(destinoMarker);
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeMap();
    });
  },
};
</script>
  
  <style>
#map {
  border: 1px solid #ccc;
  border-radius: 15px;
  height: 50vh; /* Por ejemplo, ocupar toda la pantalla */
  width: 100%; /* Ancho completo */
}
.leaflet-control-attribution {
  display: none !important;
}
</style>
  