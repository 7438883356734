import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import confirmaIngreso from '@/components/start/confirmarIngreso.vue'
import start from '@/components/start/start.vue'
import registraNuevaCarrera from '@/components/carrera/registraNuevaCarrera.vue'
import modificaCarrera from "@/components/carrera/modificaCarrera.vue"
import cerrarCarrera from "@/components/carrera/cerrarCarrera.vue"

import lista_carrera from '@/components/carrera/lista_carrera.vue'
import ver_mapa_carrera from '@/components/carrera/ver_mapa_carrera.vue'
import login from '@/components/start/login.vue'
import registro from '@/components/seguridad/registroForm.vue'
import validaregistro from '@/components/seguridad/validarRegistroForm.vue'
import cambiaclave from '@/components/seguridad/cambiaClaveForm.vue'

import listaservicio from '@/components/servicio/lista_servicio.vue'
import cambiarCondiguracionServicio from '@/components/servicio/cambia_servicio.vue'

import lista_combustible from '@/components/combustible/lista_combustible.vue'
import nuevo_combustible from '@/components/combustible/nuevo_combustible.vue'
import nuevo_gasto from '@/components/gasto/nuevo_gasto.vue'
import dashboard from '@/components/carrera/dashboard.vue'

import movimiento_ruta_carrera from "@/components/carrera/movimiento_ruta_carrera.vue"

const routes = [
  /*{
    path: '/',
    name: 'home',
    component: HomeView
  },*/
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/registro',
    name: 'registro',
    component: registro
  },
  {
    path: '/validaregistro',
    name: 'validaregistro',
    component: validaregistro
  },
  {
    path: '/cambiaclave',
    name: 'cambiaclave',
    component: cambiaclave
  },
  {
    path: '/registraNuevaCarrera',
    name: 'registraNuevaCarrera',
    component: registraNuevaCarrera
  },
  {
    path: '/modificaCarrera',
    name: 'modificaCarrera',
    component: modificaCarrera
  },
  
  {
    path: '/cerrarCarrera',
    name: 'cerrarCarrera',
    component: cerrarCarrera
  },
  {
    path: '/start',
    name: 'start',
    component: start
  },
  {
    path: '/listacarreras',
    name: 'listacarreras',
    component: lista_carrera
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard
  },
  {
    path: '/listacombustible',
    name: 'listacombustible',
    component: lista_combustible
  },
  {
    path: '/nuevocombustible',
    name: 'nuevocombustible',
    component: nuevo_combustible
  },
  {
    path: '/nuevogasto',
    name: 'nuevogasto',
    component: nuevo_gasto
  },
  {
    path: '/listaservicio',
    name: 'listaservicio',
    component: listaservicio
  },
  {
    path: '/cambiarCondiguracionServicio/:uuid',
    name: 'cambiarCondiguracionServicio',
    component: cambiarCondiguracionServicio
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/confirmaIngreso/:usuario',
    name: 'confirmaIngresoUsuarioMal',
    component: confirmaIngreso
  },
  {
    path: '/confirmaIngreso/:usuario/:token',
    name: 'confirmaIngresoUsuario',
    component: confirmaIngreso
  },
  {
    path: '/confirmaIngreso',
    name: 'confirmaIngreso',
    component: confirmaIngreso,
  },
  {
    path: '/:catchAll(.*)', // Usando :catchAll para definir la ruta comodín
    beforeEnter() {
      window.location.href = 'https://www.google.com'; // Redirigir a Google
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
