<template>
  <v-dialog
    persistent
    width="auto"
    height="auto"
    max-width="95%"
    v-if="dialogEvalListaGasto"
    v-model="dialogEvalListaGasto"
  >
    <v-card
      :class="'mx-auto'"
      :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
      elevation-6
      width="95%"
      max-width="95%"
    >
      <v-card-title
        style="height: 50px"
        :style="'color: ' + paletColor.buttons.cancel.text + ';position: sticky;top: 0; z-index: 2;background-color: '+paletColor.card.backgroundgral+';'
        +'paletColor.card.backgroundgral;padding: 16px;font-size: 18px;font-weight: bold;'"
      >
        <v-row>
          <v-col>{{ title }}</v-col>
          <v-col align="right" cols="1"
            ><v-btn
              :style="
                'background-color: ' + paletColor.card.backgroundgral + ';'
              "
              density="compact"
              icon="mdi-close-circle-outline"
              @click="cerrar"
            ></v-btn
          ></v-col>
        </v-row>
      </v-card-title>

      <v-card-text :style="'background-color: ' + paletColor.colorNeutro + ';'">
        <v-row dense>
          <v-col cols="12" md="4"  v-for="(carrera, index) in items"
          :key="index">
          <v-card
            :class="'mx-auto'"
            :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
            elevation-6>
            <v-card-title
            :style="'color: ' + paletColor.buttons.cancel.text + ';'">
              {{ formatoMonedaChile(carrera.valor) }}<br>
            </v-card-title>
            <v-card-subtitle :style="'background-color: ' + paletColor.colorNeutro + ';'">
              Comrpobante: {{ carrera.comprobante }}
            </v-card-subtitle>
            <v-card-text class="text-caption" :style="'background-color: ' + paletColor.colorNeutro + ';'">
              <v-row dense>
                <v-col cols="12">
                  {{ carrera.descripcion }}
                </v-col>
                <v-col cols="6">Fecha del gasto:</v-col>
                <v-col cols="6" class="text-right">{{ carrera.fecha_gasto }}</v-col>
                <v-col cols="6">Cuota:</v-col>
                <v-col cols="6" class="text-right">{{ carrera.ncuota }} de {{ carrera.cuotas }}</v-col>
                <v-col cols="6">Fecha del cuota:</v-col>
                <v-col cols="6" class="text-right">{{ carrera.fecha_pago }}</v-col>
                <v-col cols="6">Valor Conductor</v-col>
                <v-col cols="6" class="text-right">{{ formatoMonedaChile(carrera.valor_conductor) }}</v-col>
                <v-col cols="6">Valor Titular</v-col>
                <v-col cols="6" class="text-right">{{ formatoMonedaChile(carrera.valor_titular) }}</v-col>
                <v-col cols="6">Valor vehiculo</v-col>
                <v-col cols="6" class="text-right">{{ formatoMonedaChile(carrera.valor_vehiculo) }}</v-col>
              </v-row>
        </v-card-text>
        </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  
  <mensajeUsuario ref="mensajeUsuario" />
  <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="cerrar" />
</template>
  
  <script>
import { obtenerJsonGET, paletColor, isMobile,formatoMonedaChile } from "@/helpers/helpers";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
export default {
  data: () => ({
    title: "Listado de gastos",
    isMobile: isMobile(),
    formatoMonedaChile: formatoMonedaChile,
    dialogEvalListaGasto: false,
    paletColor: paletColor,
    items: [],
    periodo: null,
    fec_ini: null,
    fec_fin: null,
  }),
  components: {
    mensajeUsuario
  },
  methods: {
    cargarGastos: async function () {
      let param = {};
      if(this.periodo){
        param.periodo = this.periodo;
      }
      if(this.fec_ini){
        param.fecini = this.fec_ini;
      }
      if(this.fec_fin){
        param.fecfin = this.fec_fin;
      }
      let data = await obtenerJsonGET("/gasto/obtener_listado/",param);
      if (data.error == 0) {
        this.items = data.data;
        if(this.items.length <= 0){
          this.$refs.mensajeUsuarioSalir.mostrar("No hay gastos en el período seleccionado");
        }
      } else {
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      }
    },
    cerrar: function () {
      this.dialogEvalListaGasto = false;
    },
    SalirSistema: function () {
      this.$router.replace("/");
    },
  },
};
</script>
  
  <style>
</style>