<template>
    
  <v-card
    :class="'rounded-xl ma-2 '+(servicio.uso_conductor == 1?'bg-teal-lighten-4':'bg-light-blue-lighten-4')"
    @click="configServicioConductor(servicio)"
  >
    <v-card-title class="text-subtitle-2">
      {{ servicio.nombre }}
    </v-card-title>
    <v-card-text dense class="text-caption">
      <v-row dense>
        <v-col cols="3" align="right"> Comisión </v-col>
        <v-col cols="3" align="right">
          {{ formatearNumero(servicio.porcentaje_comision?servicio.porcentaje_comision:0, 2) }} %
        </v-col>
        <v-col cols="3" align="right"> Descuento </v-col>
        <v-col cols="3" align="right">
          {{ formatearNumero(servicio.porcentaje_descuento?servicio.porcentaje_descuento:0, 2) }} %
        </v-col>
        <v-col cols="7" align="right"> Bajada Bandera </v-col>
        <v-col cols="5" align="right">
          {{ formatoMonedaChile(servicio.bajada_bandera?servicio.bajada_bandera:0, 2) }}
        </v-col>
        <v-col cols="7" align="right"> Valor Kilometro </v-col>
        <v-col cols="5" align="right">
          {{ formatoMonedaChile(servicio.valor_kilometro?servicio.valor_kilometro:0, 2) }}
        </v-col>
        <v-col cols="7" align="right"> Valor TAG </v-col>
        <v-col cols="5" align="right">
          {{ formatoMonedaChile(servicio.valor_tag?servicio.valor_tag:0, 2) }}
        </v-col>
        <v-col cols="7" align="right"> Valor Min Trafico </v-col>
        <v-col cols="5" align="right">
          {{ formatoMonedaChile(servicio.valor_minuto_trafico?servicio.valor_minuto_trafico:0, 2) }}
        </v-col>
        <v-col cols="7" align="right"> Valor Min Espera </v-col>
        <v-col cols="5" align="right">
          {{ formatoMonedaChile(servicio.valor_minuto_espera?servicio.valor_minuto_espera:0, 2) }}
        </v-col>
        <v-col cols="7" align="right"> Minutos Espera permitido </v-col>
        <v-col cols="5" align="right">
          {{ servicio.minuto_espera_permitido?servicio.minuto_espera_permitido:0 }}
        </v-col>
        <v-col cols="7" align="right"> Minutos en tráfico permitido </v-col>
        <v-col cols="5" align="right">
          {{ servicio.minuto_trafico_permitido?servicio.minuto_trafico_permitido:0 }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  
  <mensajeUsuario ref="mensajeUsuario" />
  <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="SalirSistema" />
</template>

<script>
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import { paletColor, obtenerJsonGET, formatearNumero,formatoMonedaChile } from "@/helpers/helpers";
export default {
  data: () => ({
    formatearNumero: formatearNumero,
    formatoMonedaChile: formatoMonedaChile,
  }),
  props: {
    servicio: Object,
  },
  components:{
    mensajeUsuario,
  },
  methods: {
    configServicioConductor: function (servicio) {
        if(servicio.uso_conductor == 1){
            this.$router.push("/cambiarCondiguracionServicio/" + servicio.id);
        }else{
            this.$refs.mensajeUsuario.mostrar("Este servicio no es administrado por conductores.");
        }
    },
    SalirSistema: function(){
        this.$router.replace("/");
    }
  },
};
</script>

<style>
</style>