<template>
  <v-dialog
    persistent
    width="90%"
    max-width="90%"
    v-if="dialogbuscarlocalidadmapa"
    v-model="dialogbuscarlocalidadmapa"
  >
    <v-form v-model="validForm" @submit.prevent="entregarDatos">
      <v-card
        elevation-6
        :style="
          'background-color: ' +
          paletColor.card.backgroundgral +
          ';color: ' +
          paletColor.colorNeutro +
          ';'
        "
        width="100%"
        max-width="100%"
      >
        <v-card-title> Seleccione el {{ titulo }} </v-card-title>
        <v-card-text
          :style="'background-color: ' + paletColor.colorFondoAlternativo + ';'"
        >
          <div
            id="map-container"
            :style="`
            width: 100%;
            height: 400px; /* Ajusta esta altura según el diseño */
            max-height: 100%;
          `"
          >
            <mapa_carrera
              v-id="initialLatLng"
              :initialLatLng="center"
              @setLatLng="getLatLng"
            />
          </div>
        </v-card-text>
        <v-card-action>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <buttonSubmitform labelSet="Seleccionar"
              /></v-col>
              <v-col cols="12" md="6"
                ><buttonCancelform labelSet="Cancelar" @cerrar="cerrar"
              /></v-col>
            </v-row>
          </v-container>
        </v-card-action>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { paletColor } from "@/helpers/helpers";
import buttonSubmitform from "../form/buttonSubmitform.vue";
import buttonCancelform from "../form/buttonCancelform.vue";
import { createLogger } from "vuex";
import mapa_carrera from "@/components/mapa/selecciona_ubicacion.vue";
export default {
  name: "Mapa",
  data: () => ({
    zoom: 15,
    paletColor: paletColor,
    validForm: null,
    dialogbuscarlocalidadmapa: false,
    center: { lat: -33.4489, lng: -70.6693 }, // Centro del mapa, por ejemplo Santiago, Chile
    currentLocation: null,
    version: process.env.VUE_APP_VERSION,
    defaultIcon: "img/iconos/IconoMapaLocations.gif", // Ícono del marcador
    //defaultIcon: { url: '/img/iconos/LogoDriverIn.gif', scaledSize:  new VueGoogleMaps.maps.Size(32, 32)}, // Ajusta el icono del marcador
    lat: null, // Almacena la latitud
    lng: null, // Almacena la longitud
    address: null, // Dirección obtenida
    localMultidestino: false,
    localtipoLlamado: null,
    apiUrl: process.env.VUE_APP_API_URL,
    debugMode: process.env.VUE_APP_DEBUG_MODE === "true", // Convierte a booleano si es necesario



    textPolyline: null,
    legs_local: [],
  }),
  props: {
    latitud: Number,
    longitud: Number,
    titulo: String,
  },
  components: {
    buttonSubmitform,
    buttonCancelform,
    mapa_carrera,
  },
  watch: {
    latitud: function (val) {
      this.lat = val;
      this.center.lat = val;
    },
    longitud: function (val) {
      this.lng = val;
      this.center.lng = val;
    },
  },
  mounted: async function () {
    const usuario = JSON.parse(sessionStorage.getItem("usuarioDriverIn"));
  },
  methods: {
    /**
     * Consideraciones de negocio
     * this.localMultidestino
     * this.tipoLlamado //CUALQUIER TEXTO A USAR EN POSTERIOR
     * latitud: Number, // Contiene la latitud que se está seleccionando
     * longitud: Number, // Contiene la longitud que se está seleccionando
     */

    aperturaMapa: async function () {
      if (arguments.length > 0) {
        this.localtipoLlamado = arguments[0];
      } else {
        this.localtipoLlamado = "origen";
      }
      if (arguments.length > 1) {
        if (typeof arguments[1] == "object") {
          if (arguments[1].lat && arguments[1].lng) {
            this.lat = arguments[1].lat;
            this.lng = arguments[1].lng;
            this.center = {
              lat: this.lat,
              lng: this.lng,
            };
            this.currentLocation = this.center;
          } else {
            this.getCurrentLocation();
          }
        } else {
          this.getCurrentLocation();
        }
      } else {
        this.getCurrentLocation();
      }
      if (arguments.length > 2) {
        this.localMultidestino = arguments[2];
      }
      this.dialogbuscarlocalidadmapa = true;
    },
    /**
     * Funcion que sólo es llamada al seleccionar un mapa
     * @param event Solo es llamado al seleccionar Mapa
     */
    getLatLng: async function (posicion) {
      this.lat = posicion.lat;
      this.lng = posicion.lng;
      this.center = {
        lat: this.lat,
        lng: this.lng,
      };
      this.currentLocation = this.center;
      await this.getAddress(this.currentLocation); // Obtener dirección // NO FUNCIONA
    },
    /**
     * Permite obtener dirección y/o ubicación actual
     * No debe usarse si se está pasando una dirección por parámetro.
     */
    getCurrentLocation: async function () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
            this.currentLocation = {
              lat: this.lat,
              lng: this.lng,
            };
            this.center = this.currentLocation; // Centrar el mapa en la ubicación actual
            this.getAddress(this.currentLocation); // Obtener dirección
          },
          (error) => {
            console.error("Error al obtener la ubicación: ", error);
          }
        );
      } else {
        console.error(
          "La geolocalización no está soportada en este navegador."
        );
      }
    },
    getAddress: async function (latLng) {
      if (!this.$gmapApi) return; // Verifica que la librería de Google Maps esté cargada

      const geocoder = new this.$gmapApi.Geocoder();
      try {
        const results = await new Promise((resolve, reject) => {
          geocoder.geocode({ location }, (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                resolve(results[0].formatted_address);
              } else {
                reject("No se encontraron resultados");
              }
            } else {
              reject("Geocoding no fue exitoso: " + status);
            }
          });
        });
        this.address = results; // Asigna la dirección obtenida
      } catch (error) {
        console.error(error);
        this.address = ""; // Limpia la dirección en caso de error
      }
    },
    entregarDatos: function () {
      if (this.localMultidestino != null) {
        if (this.lat && this.lng) {
          this.$emit(
            "seleccionMaps",
            this.lat,
            this.lng,
            this.localtipoLlamado,
            this.localMultidestino
          );
        }
      } else {
        if (this.lat && this.lng) {
          this.$emit(
            "seleccionMaps",
            this.lat,
            this.lng,
            this.localtipoLlamado
          );
        }
      }
      this.dialogbuscarlocalidadmapa = false;
    },
    cerrar: function () {
      this.dialogbuscarlocalidadmapa = false;
    },
  },
};
</script>

<style>
.coordinates {
  margin-top: 20px;
}
.gmap-marker {
  width: 32px; /* Ajusta el tamaño según sea necesario */
  height: 32px; /* Ajusta el tamaño según sea necesario */
}
</style>