<template>
    <v-container>
      <v-form
        ref="form_ingreso"
        v-model="validForm"
        @submit.prevent="ingresarDriverIn"
      >
        <v-card
          class="mx-auto"
          elevation-6
          :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
          max-width="90%"
        >
          <v-card-title style="height: 80px">
            <v-img :src="obtenerLogoApp()" height="50px"></v-img>
          </v-card-title>
          <v-card-text
            :style="'background-color: ' + paletColor.colorNeutro + ';'"
          >
            <div v-if="pantalla == 1">
              <v-row dense no-gutters class="text-caption">
                <!--v-col cols="12" md="6">
                <v-img src="/img/start/conductor.png" width="100%"></v-img>
              </v-col-->
                <v-col cols="12" class="pa-md-6">
                  <v-row dense class="text-caption">
                    <v-col cols="12">
                      <span class="text-h5">Validación de correo</span>
                    </v-col>
                  <v-col cols="12" md="6">
                    <cmp_email
                      v-model="email"
                      :requeridoSet="true"
                      :validatingSet="validating"
                    ></cmp_email>
                  </v-col>
                    <v-col cols="12" md="6">
                        <v-otp-input 
                        v-model="codigo"
                        length="6"
                        type="password"></v-otp-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!--
                    Esto sólo se muestra si es mobile
                    -->
                    <v-col cols="12" v-if="isMobile">
                      <cmp_submit
                        labelSet="Regtistrar"
                        :validating="validating"
                      />
                    </v-col>
                    <v-col :cols="isMobile ? 12 : 6">
                      <cmp_cancel
                        labelSet="Volver"
                        :validating="validating"
                        @cerrar="volverLogin"
                      />
                    </v-col>
                    <!--
                    Esto sólo se muestra si es aplicación
                    -->
                    <v-col cols="6" v-if="!isMobile">
                      <cmp_submit
                        labelSet="Regtistrar"
                        :validating="validating"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div v-if="pantalla == 2" class="pa-6">
                ¡Correo validado!<br><br>
                Se ha enviado a tu correo una clave temporal, con ella... <br><br>
                
            <cmp_cancel
                      labelSet="¡Ingresa al sistema!"
                      :validating="validating"
                      @cerrar="volverLogin"
                    />
            </div>
          </v-card-text>
          <v-card-action class="pa-1">
            <v-row> </v-row>
          </v-card-action>
        </v-card>
      </v-form>
    </v-container>
    <mensajeUsuario ref="mensajeUsuario" />
  </template>
      
      <script>
  import { enviarJsonPOST, paletColor, isMobile, obtenerLogoApp } from "@/helpers/helpers";
  import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
  import cmp_email from "@/components/form/email.vue";
  import cmp_cancel from "@/components/form/buttonCancelform.vue";
  import cmp_submit from "@/components/form/buttonSubmitform.vue";
  import cmp_check from "@/components/form/checkBoxMultiple.vue";
  export default {
    name: "Login",
    data: () => ({
      validForm: null,
      validating: null,
      pantalla: 1,
      email: null,
      codigo: null,
      paletColor: paletColor,
      isMobile: isMobile(),
      obtenerLogoApp: obtenerLogoApp,
    }),
    components: {
      cmp_cancel,
      cmp_submit,
      cmp_email,
      mensajeUsuario,
    },
    props: {},
    methods: {
      volverLogin: function () {
        window.location.replace("/");
      },
      ingresarDriverIn: async function () {
        const { valid } = await this.$refs.form_ingreso.validate();
        this.validating = true;
        if (valid) {
          let fd = new FormData();
          fd.append("codigo", this.codigo);
          fd.append("email", this.email);
          let data = await enviarJsonPOST(
            "/usuario/valida_cuenta_conductor/",
            fd
          );
          if (data) {
            if (data.error == 0) {
              this.pantalla = 2;
            } else {
              this.$refs.mensajeUsuario.mostrar(data.message);
            }
          } else {
            this.$refs.mensajeUsuario.mostrar(
              "ocurrio un problema al intentar validar al usuario."
            );
          }
        } else {
          this.$refs.mensajeUsuario.mostrar("Debe ingrersar todos los datos");
        }
        this.validating = false;
      },
    },
  };
  </script>
      
      <style>
  </style>