<template>
  <v-container>
    <v-form
      ref="form_ingreso"
      v-model="validForm"
      @submit.prevent="ingresarDriverIn"
    >
      <v-card
        class="mx-auto"
        elevation-6
        :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
        max-width="90%"
      >
        <v-card-title style="height: 80px">
          <v-img
            :src="obtenerLogoApp()"
            height="50px"
          ></v-img>
        </v-card-title>
        <v-card-text
          :style="'background-color: ' + paletColor.colorNeutro + ';'"
        >
          <v-row dense no-gutters class="text-caption">
            <v-col cols="12" md="6">
              <v-img src="/img/start/conductor.png" width="100%"></v-img>
            </v-col>
            <v-col cols="12" md="6" class="pa-md-6">
              <v-row dense class="text-caption">
                <v-col cols="12">
                  <span class="text-h5">Ingreso Conductor</span>
                </v-col>
                <v-col cols="12">
                  <cmp_email
                    v-model="email"
                    :requeridoSet="true"
                    :validatingSet="validating"
                  ></cmp_email>
                </v-col>
                <v-col cols="12">
                  <cmp_password
                    v-model="clave"
                    :requeridoSet="true"
                    :validatingSet="validating"
                  ></cmp_password>
                </v-col>
                <v-col cols="12" class="text-right" @click="RegistroConductor">
                  <a href="/registro" class="text-decoration-none text-primary">
                    ¡Regístrate!
                  </a>
                </v-col>
              </v-row>
              <v-row>
                <!--
                Esto sólo se muestra si es mobile
                -->
                <v-col cols="12" v-if="isMobile">
                  <cmp_submit :validating="validating" />
                </v-col>
                <v-col :cols="isMobile?12:6">
                  <cmp_cancel
                    labelSet="Volver"
                    :validating="validating"
                    @cerrar="volverDriverIn"
                  />
                </v-col>
                <!--
                Esto sólo se muestra si es aplicación
                -->
                <v-col cols="6" v-if="!isMobile">
                  <cmp_submit :validating="validating" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-action class="pa-1">
          <v-row> </v-row>
        </v-card-action>
      </v-card>
    </v-form>
  </v-container>
  <mensajeUsuario ref="mensajeUsuario" />
</template>
  
  <script>
import { enviarJsonPOST, paletColor,isMobile,obtenerLogoApp,obtenerDominio } from "@/helpers/helpers";
import mensajeUsuario from "@/components//corss/mensajeUsuario.vue";
import cmp_email from "@/components/form/email.vue";
import cmp_password from "@/components/form/password.vue";
import cmp_cancel from "@/components/form/buttonCancelform.vue";
import cmp_submit from "@/components/form/buttonSubmitform.vue";
export default {
  name: "Login",
  data: () => ({
    validForm: null,
    validating: null,
    email: null,
    clave: null,
    paletColor: paletColor,
    isMobile: isMobile(),
    obtenerLogoApp: obtenerLogoApp,
    obtenerDominio: obtenerDominio,
  }),
  components: {
    cmp_email,
    cmp_password,
    cmp_cancel,
    cmp_submit,
    mensajeUsuario,
  },
  props: {},
  methods: {
    volverDriverIn: function () {
      
      let dom = "https://"+this.obtenerDominio().dominio;
      console.log(dom);
      window.location.replace(dom);
    },
    RegistroConductor: function(){
      this.$router.replace("/registro");
    },
    ingresarDriverIn: async function () {
      const { valid } = await this.$refs.form_ingreso.validate();
      this.validating = true;
      if (valid) {
        let fd = new FormData();
        fd.append("email", this.email);
        fd.append("clave", this.clave);
        let data = await enviarJsonPOST("/usuario/validar_cuenta_conductor/", fd);
        if (data) {
          if (data.error == 0) {
            if(data.data.estado_usuario){
              if(data.data.estado_usuario == 100){
                this.$router.replace("/validaregistro");
              }else if(data.data.estado_usuario == 200 || data.data.estado_usuario == 300){
                this.$router.replace("/cambiaclave");
              }
            }else{
              sessionStorage.setItem(
                "usuarioDriverIn",
                JSON.stringify(data.data)
              );
              this.$router.replace("/start");
            }
          } else {
            this.$refs.mensajeUsuario.mostrar(data.message);
          }
        } else {
          this.$refs.mensajeUsuario.mostrar(
            "ocurrio un problema al intentar validar al usuario."
          );
        }
      } else {
        this.$refs.mensajeUsuario.mostrar("Debe ingrersar todos los datos");
      }
      this.validating = false;
    },
  },
};
</script>
  
  <style>
</style>