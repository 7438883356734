<template>
  <v-container :class="isMobile ? 'mx-0 px-0 my-1 py-1' : ''">
    <v-row dense>
      <v-col cols="12">
        <v-card
          :class="'mx-auto'"
          :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
          elevation-6
          max-width="90%"
        >
          <v-card-title
            style="height: 50px"
            :style="'color: ' + paletColor.buttons.cancel.text + ';'"
          >
            <v-row>
              <v-col>Utilidad y Gasto</v-col>
              <v-col align="right" cols="1"
                ><v-btn
                  :style="
                    'background-color: ' + paletColor.card.backgroundgral + ';'
                  "
                  density="compact"
                  icon="mdi-close-circle-outline"
                  @click="volverMenu"
                ></v-btn
              ></v-col>
            </v-row>
          </v-card-title>
          <v-card-text
            :style="'background-color: ' + paletColor.colorNeutro + ';'"
          >
            <v-row dense>
              <v-col cols="12">
                <v-expansion-panels dense>
                  <v-expansion-panel dense>
                    <v-expansion-panel-title class="text-caption" dense>
                      <template v-slot:default="expanded">
                        <v-row no-gutters>
                          <v-col class="d-flex justify-start" v-if="!expanded">
                            <v-icon>mdi-magnify</v-icon>
                            Seleccione los filtros que desea usar
                          </v-col>
                          <v-col class="text-grey" v-if="expanded">
                            <v-fade-transition leave-absolute>
                              <span v-if="expanded" key="0">
                                <v-icon>mdi-magnify</v-icon>
                                Busqueda
                              </span>
                            </v-fade-transition>
                          </v-col>
                        </v-row>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <v-row>
                        <v-col cols="12">
                          <cbo_combobox
                            labelSet="Filtro"
                            :itemsSet="filtro"
                            :valorSet="filtroSel"
                            @SelectValue="cambioFiltro"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="filtroSel && filtroSel.cod == '000000'"
                        >
                          <cbo_combobox
                            labelSet="Período"
                            :itemsSet="periodos"
                            :valorSet="periodoSel"
                            @SelectValue="cambioFiltroPeriodo"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="filtroSel && filtroSel.cod == '999999'"
                        >
                          <v-row dense>
                            <v-col cols="12" md="6">
                              <fec_fecha
                                labelSet="Inicio"
                                :valorSet="fecIni"
                                :minFecSet="minFecDesde"
                                :maxFecSet="maxFecDesde"
                                @SetValueChange="ChangeMin"
                              />
                            </v-col>
                            <v-col cols="12" md="6">
                              <fec_fecha
                                labelSet="Término"
                                :valorSet="fecFin"
                                :minFecSet="minFecHasta"
                                :maxFecSet="maxFecHasta"
                                @SetValueChange="ChangeMax"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6">
                <v-card>
                  <v-card-text class="text-caption">
                    <canvas ref="chartCanvas" height="300px"></canvas>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="mt-1">
                  <v-card-text class="text-caption">
                    <v-row dense>
                      <v-col cols="12" v-if="periodo > 0"
                        >Período {{ periodo }}</v-col
                      >
                      <v-col cols="12" v-if="fecIni && fecFin"
                        >Fechas entre<br />el {{ formatoFechaHoraTXT(fecIni) }}
                        <br />y el {{ formatoFechaHoraTXT(fecFin) }}</v-col
                      >
                      <v-col cols="6">Ingresos x viaje ({{viajes}}):</v-col>
                      <v-col cols="6" align="right">{{
                        (ingresos > 0 ? "(+) " : "") +
                        formatoMonedaChile(ingresos)
                      }}</v-col>
                      <v-col cols="6">Comisión Servicio:</v-col>
                      <v-col cols="6" align="right">{{
                        (totalComision > 0 ? "(-) " : "") +
                        formatoMonedaChile(totalComision)
                      }}</v-col>
                      <v-col cols="6"><v-btn density="compact" class="text-caption" prepend-icon="mdi-information-outline" @click="muestraCombustible">Combustible ({{ totalCombustible.cantidad }}):</v-btn></v-col>
                      <v-col cols="6" align="right">{{
                        (totalCombustible.valor > 0 ? "(-) " : "") +
                        formatoMonedaChile(totalCombustible.valor)
                      }}</v-col>
                      <v-col cols="6"><v-btn density="compact" class="text-caption" prepend-icon="mdi-information-outline" @click="muestraTag">Tag ({{totalTag.cantidad}}):</v-btn></v-col>
                      <v-col cols="6" align="right">{{
                        (totalTag.valor > 0 ? "(-) " : "") +
                        formatoMonedaChile(totalTag.valor)
                      }}</v-col>
                      <v-col cols="6"><v-btn density="compact" class="text-caption" prepend-icon="mdi-information-outline" @click="muestraGastos"> Otros Gastos ({{ otrosGastos.cantidad }}):</v-btn></v-col>
                      <v-col cols="6" align="right"
                        >{{
                            (otrosGastos.valor > 0 ? "(-) " : "") +
                            formatoMonedaChile(otrosGastos.valor)
                          }}
                          </v-col
                      >
                      <v-col cols="6">Utilidad:</v-col>
                      <v-col cols="6" align="right">(=) {{
                        formatoMonedaChile(utilidad)
                      }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <mensajeUsuario ref="mensajeUsuario" />
  <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="salirApp" />
  <cmp_lista_gasto ref="listarGastos"/>
  <cmp_lista_combustible ref="listarCombustible"/>
</template>
    
    <script>
import {
  formatoMonedaChile,
  formatoFechaHoraVue,
  formatoFechaHoraTXT,
  paletColor,
  obtenerJsonGET,
  isMobile,
} from "@/helpers/helpers";
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import { Chart, registerables } from "chart.js";
import cbo_combobox from "@/components/form/combobox.vue";
import fec_fecha from "@/components/form/fechaHora.vue";
import cmp_lista_gasto from "@/components/gasto/lista_gasto.vue";
import cmp_lista_combustible from "@/components/combustible/lista_combustible.vue";

// Registrar componentes de Chart.js
Chart.register(...registerables);

export default {
  name: "ChartBar",
  data: () => ({
    isMobile: isMobile(),
    paletColor: paletColor,
    chartInstance: null, // Referencia al gráfico
    formatoMonedaChile: formatoMonedaChile,
    formatoFechaHoraVue: formatoFechaHoraVue,
    formatoFechaHoraTXT: formatoFechaHoraTXT,
    ingresos: 0,
    periodo: 0,
    totalCombustible: {
      cantidad: 0,
      valor: 0,
    },
    utilidad: 0,
    totalTag: {
      cantidad: 0,
      valor: 0,
    },
    otrosGastos: {
      cantidad: 0,
      valor: 0,
    },
    totalComision: 0,
    periodos: [],
    periodoSel: null,
    filtro: [],
    filtroSel: null,
    minFecDesde: null,
    maxFecDesde: new Date(),
    minFecHasta: null,
    maxFecHasta: new Date(),
    fecIni: null,
    fecFin: null,
    viajes: null,
  }),
  components: {
    mensajeUsuario,
    cbo_combobox,
    fec_fecha,
    cmp_lista_gasto,
    cmp_lista_combustible,
  },
  mounted: async function () {
    this.loadData();
  },
  methods: {
    // Método para cargar datos dinámicamente
    loadChartData: async function (days, gasto, utilidad, ingresos) {
      if (this.chartInstance) {
        await this.chartInstance.destroy(); // Destruir gráfico previo si existe
      }

      // Crear nuevo gráfico
      const canvas = this.$refs.chartCanvas;
      if (canvas) {
        const ctx = canvas.getContext("2d");
        if (ctx) {
          this.chartInstance = new Chart(ctx, {
            data: {
              labels: days,
              datasets: [
                {
                  label: "Ingresos",
                  data: ingresos,
                  backgroundColor: "rgba(0, 51, 102, 0.5)", // Color pastel azulado
                  borderColor: "rgba(0, 51, 102, 1)",
                  borderWidth: 1,
                  type: "line",
                },
                {
                  label: "Gastos",
                  data: gasto,
                  backgroundColor: "rgba(255, 99, 132,255)", // Color pastel rojo
                  borderColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 1,
                  type: "bar",
                },
                {
                  label: "Utilidad",
                  data: utilidad,
                  backgroundColor: "rgba(75, 192, 192, 0.5)", // Color pastel verde agua
                  borderColor: "rgba(75, 192, 192, 1)",
                  borderWidth: 1,
                  type: "bar",
                },
              ],
            },
            options: {
              responsive: true,
              plugins: {
                tooltip: {
                  title: {
                    display: true,
                    text: "Ingresos, utilidad y gasto",
                  },
                  callbacks: {
                    label: (tooltipItem) =>
                      `${
                        tooltipItem.dataset.label
                      }: $${tooltipItem.raw.toLocaleString()}`,
                  },
                },
                datalabels: {
                  display: true,
                  formatter: (value, context) =>
                    `$${revenues[context.dataIndex].toLocaleString()}`,
                  color: "#000",
                  anchor: "end",
                  align: "start",
                },
              },
              scales: {
                x: {
                  stacked: true,
                  title: {
                    display: true,
                    text: "Días del mes",
                  },
                },
                y: {
                  stacked: true,
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "Monto ($)",
                    min: 300,
                    max: 100,
                  },
                },
              },
            },
          });
        }
      }
    },

    loadData: async function () {
      console.log("arguments", arguments);
      let send = {};
      if (arguments.length == 1) {
        if (arguments[0].cod) {
          send.periodo = arguments[0].cod;
        }
      }
      if (arguments.length == 2) {
        send.fecIni = this.formatoFechaHoraVue(arguments[0]);
        send.fecFin = this.formatoFechaHoraVue(arguments[1]);
        this.periodo = null;
      }
      let data = await obtenerJsonGET("/carrera/obtenergraficoutilidadConductor/", send);
      if (data.error == "0") {
        const days = data.data.items.map((item) => item.fechahora);
        const expenses = data.data.items.map((item) => parseFloat(item.gasto));
        const utilities = data.data.items.map((item) =>
          parseFloat(item.utilidad)
        );
        const ingreso = data.data.items.map(
          (item) => parseFloat(item.gasto) + parseFloat(item.utilidad)
        );
        this.ingresos = parseFloat(data.data.totalIngresos);
        if (!this.periodo) {
          this.periodo = data.data.periodo;
        }
        this.viajes = parseInt(data.data.viajes);
        this.totalComision = data.data.totalComision;
        this.totalTag = data.data.tag;
        this.otrosGastos = data.data.otrosGastos;
        this.totalCombustible = data.data.totalCombustible;
        this.utilidad =
          this.ingresos -
          parseFloat(this.totalCombustible.valor) -
          parseFloat(this.totalComision) -
          parseFloat(this.totalTag.valor) -
          parseFloat(this.otrosGastos.valor);
        this.periodos = data.data.periodos;
        if (this.periodos && !this.periodoSel) {
          this.periodoSel = this.periodos[0];
        }
        this.filtro = data.data.filtro;
        if (this.filtro && !this.filtroSel) {
          this.filtroSel = this.filtro[0];
        }
        this.loadChartData(days, expenses, utilities, ingreso);
      } else {
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      }
    },
    cambioFiltro: function (val) {
      this.filtroSel = val;
      if (this.filtroSel && this.filtroSel.cod == "000000") {
        this.fecIni = null;
        this.fecFin = null;
        this.periodoSel = null;
      } else if (this.filtroSel && this.filtroSel.cod != "999999") {
        this.fecIni = null;
        this.fecFin = null;
        console.log("this.filtroSel", this.filtroSel);
        this.periodo = this.filtroSel.cod;
        this.loadData(this.filtroSel);
      }
    },
    cambioFiltroPeriodo: function (val) {
      if (val) {
        this.periodoSel = val;
        this.periodo = this.periodoSel.cod;
        this.loadData(val);
      }
    },
    ChangeMin: function (val) {
      console.log("val", val);
      if (val) {
        this.fecIni = new Date(val);

        console.log("this.fecIni", this.fecIni);
        this.minFecHasta = this.fecIni;
      } else {
        this.fecIni = null;
        this.minFecHasta = null;
      }
      if (this.fecIni && this.fecFin) {
        this.loadData(this.fecIni, this.fecFin);
      }
    },
    ChangeMax: function (val) {
      if (val) {
        this.fecFin = new Date(val);
        this.maxFecDesde = this.fecFin;
      } else {
        this.fecFin = null;
        this.maxFecDesde = new Date();
      }
      if (this.fecIni && this.fecFin) {
        this.loadData(this.fecIni, this.fecFin);
      }
    },
    muestraGastos: function(){
      this.$refs.listarGastos.periodo = this.periodo;
      if(this.fecIni){
        this.$refs.listarGastos.fec_ini = this.formatoFechaHoraVue(this.fecIni);
      }else{
        this.$refs.listarGastos.fec_ini = null;
      }
      if(this.fecFin){
        this.$refs.listarGastos.fec_fin = this.formatoFechaHoraVue(this.fecFin);
      }else{
        this.$refs.listarGastos.fec_fin = null;
      }
      this.$refs.listarGastos.dialogEvalListaGasto = true;
      this.$refs.listarGastos.cargarGastos();
    },
    muestraCombustible: function(){
      this.$refs.listarCombustible.periodo = this.periodo;
      if(this.fecIni){
        this.$refs.listarCombustible.fec_ini = this.formatoFechaHoraVue(this.fecIni);
      }else{
        this.$refs.listarCombustible.fec_ini = null;
      }
      if(this.fecFin){
        this.$refs.listarCombustible.fec_fin = this.formatoFechaHoraVue(this.fecFin);
      }else{
        this.$refs.listarCombustible.fec_fin = null;
      }
      this.$refs.listarCombustible.dialogEvalListaGasto = true;
      this.$refs.listarCombustible.cargarGastos();
    },
    muestraTag: function(){
      alert("en construcción");
    },
    volverMenu: function () {
      this.$router.go(-1);
    },
    salirApp: function () {
      this.$router.replace("/");
    },
  },
};
</script>
    
    <style scoped>
canvas {
  max-width: 100%;
  height: 500px;
}
</style>
    